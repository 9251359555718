<template>
  <el-menu
    :default-active="activeIndex"
    @select="selectMenu"
    text-color="#666666"
    active-text-color="#FFFFFF"
  >
    <el-menu-item
      v-show="(memberRole!=ApiConstants.classMemberRoleExit&&$key==menus.length-1) || $key<menus.length-1"
      v-for="(item,$key) in menus"
      :disabled="item.isEnable"
      :index="item.href"
      :key="$key"
    >
      <div class="dot" v-if="getCurrentCountry === 'indonesia' && item.href === 'goodCourse'"></div>
      <i :class="['mg-icon',item.icon]"></i>
      <span v-html="item.title"></span>
    </el-menu-item>
  </el-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UpReport from "@/utils/upReport";
import Gtag from '@/utils/gtag';




export default {
  mounted() {
    this.activeIndex = this.$route.name;
  },
  data() {
    return {
      activeIndex: '',
      parentId: '',
    };
  },
  props: {
    isRegister: {
      default: true,
      type: Boolean
    }
  },
  watch: {
    '$route'(toRouter, fromRouter) {
      let target = toRouter.name
      if (target === 'classschedulecard' || target === 'booking') {
        target = 'book'
      }
      this.activeIndex = target;
    }
  },
  computed: {
    ...mapGetters({
      memberRole: "student/getMemberRole",
      user: "user/getUserInfo",
      currentStudent: "student/getCurrentStudent",
      getCurrentCountry: "location/getCurrentCountry",
    }),
    menus() {
      return [
        {
          title: this.$i18n.t("Home"),
          icon: "icon-home",
          href: "home",
          index: "1-1",
          isEnable: false
        },
        {
          title: this.$i18n.t("Booking"),
          icon: "icon-booking",
          href: "book",
          index: "1-2",
          isEnable: false
        },
        {
          title: this.$i18n.t("Schedule"),
          icon: "icon-schedule",
          href: "schedule",
          index: "1-3",
          isEnable: false
        },
        {
          title: this.$i18n.t("History"),
          icon: "icon-history",
          href: "history",
          index: "1-4",
          isEnable: false,

        },
        {
          title: this.$i18n.t("Discover"),
          icon: "icon-discovery",
          href: "goodCourse",
          index: "1-5",
          isEnable: false
        },
        {
          title: this.$i18n.t("Group"),
          icon: "icon-group",
          href: "group",
          index: "1-6",
          isEnable: false
        }
      ]
    }
  },
  created() {
    this.getCurrentCountryAction()
  },
  methods: {
    ...mapActions({
      getCurrentCountryAction: 'location/getCurrentCountryAction'
    }),
    selectMenu(path) {
      this.$emit('selectMenu', path);
      if(path == 'history'){
        window.ga('send', 'event', 'webpage', 'view');
        UpReport.clickHistory({parentId:this.user.userParentInfo.id});

        Gtag.webHome_click({
        eventName: '点击历史课程按钮',
        name: '历史课程按钮_点击',
        parentId: this.user.userParentInfo.id,
        studentId: this.currentStudent.id

      })
        this.$Analytics.eventLog('历史课程按钮_点击')
      }
      if(path == 'book'){
        // amplitude埋点-【约课】一级入口按钮
        this.$Analytics.eventLog('CNLS_Click_Book_Class_Entry')
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
$common-width = 10px;
.dot {
  position: relative;
  top: -8px;
  display: inline-block;
  width: $common-width;
  height: $common-width;
  border-radius: $common-width;
  background: red;
}
</style>
