import Vue from 'vue';
import Router from 'vue-router';
// cris start
import homeworkRoute from '@/router/homework-router'
import orderRoute from '@/router/order-router'
// cris end
import {
  loadFromLocal
} from '@/common/js/store';
import md5 from 'md5'
import getUrlParams from '@/utils/getUrlParams.js'
import store from '@/store'

Vue.use(Router);

const router = new Router({
  mode: 'hash',
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    };
  },
  routes: [{
      path: '/',
      name: 'index',
      component: resolve => require(['@/components/index/components/Main'], resolve),
      // children: [{
      //  path: 'group',
      //  name: 'group',
      //  component: resolve => require(['@/components/group/Group'], resolve)
      // },
      children: [{
          path: 'group',
          name: 'group',
          component: resolve => require(['@/components/myGroup'], resolve)
        },
        {
          path: 'home',
          name: 'home',
          component: resolve => require(['@/components/home/Home'], resolve)
        },
        {
          path: 'history',
          name: 'history',
          component: resolve => require(['@/components/history/History'], resolve)
        },
        {
          path: 'replayVideo',
          name: 'replayVideo',
          component: resolve => require(['@/components/history/Replay'], resolve)
        },
        {
          path: 'goodCourse',
          name: 'goodCourse',
          component: resolve => require(['@/components/goodCourse/goodCourse'], resolve)
        },
        {
          path: 'videoDetail',
          name: 'videoDetail',
          component: resolve => require(['@/components/goodCourse/videoCourseDetail'], resolve)
        },
        {
          path: 'unitReport',
          name: 'unitReport',
          component: resolve => require(['@/components/unitReport/unitReport'], resolve)
        },
        {
          path: 'firstClassReport',
          name: 'firstClassReport',
          component: resolve => require(['@/components/firstClassReport/firstClassReport'], resolve)
        },
        {
          path: 'booking',
          name: 'booking',
          component: resolve => require(['@/components/booking/Booking'], resolve)
        },
        {
          path: 'book',
          name: 'book',
          component: resolve => require(['@/components/booking/Book'], resolve)
        },
        {
          path: 'schedule',
          name: 'schedule',
          component: resolve => require(['@/components/schedule/Schedule'], resolve)
        },
        {
          path: 'classschedulecard',
          name: 'classschedulecard',
          component: resolve => require(['@/components/ClassScheduleCard/classschedulecard'], resolve)
        },
        {
          path: 'detail',
          name: 'detail',
          component: resolve => require(['@/components/detail/Detail'], resolve)
        },
        {
          path: 'firstClassFeedback',
          name: 'firstClassFeedback',
          component: resolve => require(['@/components/firstClassFeedback/index'], resolve)
        }
      ],
      redirect: '/home'
    },
    {
      path: '/pay',
      name: 'pay',
      component: resolve => require(['@/components/pay/index'], resolve),
      children: [{
          path: 'pays',
          name: 'pays',
          component: resolve => require(['@/components/pay/components/pay'], resolve)
        },
        {
          path: 'coupon',
          name: 'coupon',
          component: resolve => require(['@/components/pay/components/coupon'], resolve)
        },
        {
          path: 'billing',
          name: 'billing',
          component: resolve => require(['@/components/pay/components/billing'], resolve)
        },
        {
          path: 'paymentdetails',
          name: 'paymentdetails',
          component: resolve => require(['@/components/pay/components/paymentdetails'], resolve)
        },
        {
          path: 'paymentresult',
          name: 'paymentresult',
          component: resolve => require(['@/components/pay/components/paymentResult'], resolve)
        },
        // {
        //   path: 'aceTip',
        //   name: 'aceTip',
        //   component: resolve => require(['@/components/pay/components/aceTip'], resolve)
        // },
      ],
      redirect: '/pay/pays'
    },
    {
      path: '/points',
      name: 'points',
      component: resolve => require(['@/components/points/index'], resolve),
    },
    {
      path: '/smallclass',
      name: 'smallclass',
      component: resolve => require(['@/components/smallClass/SmallClass'], resolve)
    },
    {
      path: '/referfriends',
      name: 'referfriends',
      component: resolve => require(['@/components/pay/components/referFriendsReconstruct'], resolve)
      /**
       * 上面的referFriendsReconstruct组件对应的是新的重构的转介绍页面，不区分地区，只区分中英文
       * 下面的这个注释对应的是旧版本的转介绍页面，区分新加坡地区
       */
      // component: resolve => require(['@/components/pay/components/referfriends'], resolve)
    },
    {
      path: '/aceTip',
      name: 'aceTip',
      component: resolve => require(['@/components/pay/components/aceTip'], resolve)
    },
    {
      path: '/messageCenter',
      name: 'messageCenter',
      component: resolve => require(['@/components/messageCenter/messageCenter'], resolve)
    },
    {
      path: '/classroom',
      name: 'classroom',
      component: resolve => require(['@/components/plugin/Classroom'], resolve)
    },
    {
      path: '/enterclassroom',
      name: 'enterclassroom',
      component: resolve => require(['@/components/plugin/Enterclassroom'], resolve)
    },
    {
      path: '/supplementuser',
      name: 'supplementuser',
      component: resolve => require(['@/components/supplementuser/supplementuser'], resolve)
    },
    {
      path: 'download',
      name: 'download',
      component: resolve => require(['@/components/download/Download'], resolve)
    },
    {
      path: '*',
      redirect: '/'
    }
  ].concat(homeworkRoute).concat(orderRoute)
})
function addParams(to,next) {
  if (to.query._stid) {
    next()
  }else{
    let currentChild=localStorage.getItem('currentChild')
    if (currentChild) {
      next({path:to.path,query:{...to.query,_stid:md5(+currentChild)}});
    }else{
      next()
    }
  }
}
// 浏览器前进后退时根据urlid 切换学生
window.onpopstate=function (e) {
  let stid=getUrlParams()._stid
  let students=store.getters['student/students']
  let key,currentChild;
  students.forEach((item,index)=>{
    if(stid== md5(item.id)){
      key=index
      currentChild=item
    }
  })
  if (currentChild&&currentChild.id!=store.getters['student/getCurrentStudent'].id) {
      store.commit('student/setCurrentStudent',key)
  }
}

router.beforeEach((to, from, next) => {
  let isLogin = store.getters['student/getIsLogin'];
  const user = loadFromLocal('login_info', 'user', null);
  const jwtToken = loadFromLocal('login_token', 'jwtToken', null);
  if (isLogin) {
    if (to.query.redirect) {
      if (decodeURIComponent(to.query.redirect).indexOf('billList.html') > -1) {
        window.location.href = '/billList.html';
        next();
      } else {
        next(decodeURIComponent(redirect));
      }
    } else {
      addParams(to,next)
    }

  } else if (user) {
    store.commit('user/loginMutation',{
      isLogin: true,
      user,
      jwtToken
    })
    addParams(to,next)
  }else if (!isLogin) {
    next(false);
    window.location.href = '/login.html?redirect=' + encodeURIComponent(to.fullPath)+ (to.query.languageTag?`&languageTag=${to.query.languageTag}`:'');
  } else {
    addParams(to,next)
  }

})
// 解决重复点击导航路由报错
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}
export default router;
