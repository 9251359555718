import { saveToLocal, lgLocalStorage, clearLocalExcept } from "@/common/js/store";
import unreport from "../../utils/upReport"
import { urlParse } from "@/common/js/util";
import api from '@/api'
import MD5 from "crypto-js/md5";
import Gtag from '@/utils/gtag';

const state = {
  user: {
    userParentInfo: {
      balance: 0,
      bonusBalance: 0
    }
  },
  jwtToken:'',
  isLogin: false,
  teacherInfo: {},
  studentBriefInfo: {},
  multiSubjectTypeList: [],//所有学科类型列表
  tipType: 0, //是否重新发送邮件验证
}

const getters = {
  getParentInfo: (state) => {
    let userParentInfo;
    if (state.user.userParentInfo.id) {
      return state.user.userParentInfo
    } else {
      let userInfo = lgLocalStorage('__lgzy__s__');
      if (userInfo && userInfo.login_token.jwtToken) {
        userParentInfo = userInfo.login_info.user.userParentInfo;
      }
      return userParentInfo;
    }
  },
  getIsLogin: (state) => state.isLogin,
  getToken: (state) => state.jwtToken,
  getUserInfo: (state) => state.user,
  getBlance: (state) => state.user.userParentInfo.bonusBalance * 1 + state.user.userParentInfo.balance * 1,
  getTeacherInfo: (state) => state.teacherInfo,
  getStudentBriefInfo: (state) => state.studentBriefInfo,
  getSubjectTypeList: (state) => {
    return state.multiSubjectTypeList?.filter(item => item.typeCode != -1) || []
  },
  getSubjectTypeSelectList: (state) => {
    return state.multiSubjectTypeList || [];
  },
  getTipType: (state) => state.tipType
}

const actions = {
  /**
   * @Author   Yaodongxin
   * @DateTime 2019-12-12
   * @method   loginAction
   * @param    {function}   options.commit commit
   * @param    {object}     options.user   用户登录信息
   * @param    {object}     options.self   vue组件对象
   */
  loginAction({ commit }, { user, self, callback }) {
    return new Promise((resolve, reject) => {
      api.user.login(user).then(({ status, data }) => {
        if (status === 200) {
          if (data.code === 200) {
            console.log("登录接口数据",data.data);
            saveToLocal("login_info", "user", data.data);
            saveToLocal("login_token", "jwtToken", data.data.jwtToken);
            commit('loginMutation', {
              isLogin: true,
              jwtToken: data.data.jwtToken,
              user: data.data
            });
            Gtag.appLgGtagJs({
              eventName:'登录成功',
              name:'login',
              referPath:window.sessionStorage.getItem('referPath')
            });
            let querys;
            try {
              querys = self.$router.query
            } catch (e) {
              querys = urlParse();
            }
            // if(self.$router.query && self.$router.redirect) {
            //   self.$router.push(self.$router.query.redirect);
            // }else {
            //   self.$router.push("/index");
            // }
            // 登录或者注册成功以后更改隐私协议状态
            api.common.updatePrivacypolicy([
              {
                'status': 1,
                'type': 1,
                'userId': data.data.userParentInfo.id,
                'version': user.version1
              },
              {
                'status': 1,
                'type': 2,
                'userId': data.data.userParentInfo.id,
                'version': user.version2
              }
            ]).then(res => {
              // console.log(res,'90-6789')
              if (res && res.data && res.data.code === 200) {
                if (querys && querys.redirect) {
                  let redirect = decodeURIComponent(decodeURIComponent(querys.redirect));
                  if (redirect.indexOf('billList.html') > -1) {
                    window.location.href = redirect;
                  } else {
                    window.location.href = "/#" + redirect;
                  }
                } else {
                  window.location.href = "/";
                }
                resolve(data);
                if (typeof callback == 'function') {
                  callback();
                }

              }
            })

          } else {
            reject('error');
          }
        } else {
          reject('error');
        }
      });
    });
  },
  /**
   * @Author   Yaodongxin
   * @DateTime 2019-12-12
   * @method   logoutAction  退出登录
   * @param    {function}    options.commit  commit
   * @param    {function}    cal             成功回调
   */
  async logoutAction({ commit }, cal) {
    const { status, data } = await api.user.logout()
    if (status !== 200 || data.code !== 200) {
      return;
    }
    // 清除本地存储
    let parentUseId = JSON.parse(window.localStorage.getItem("parentUseId"))
    clearLocalExcept();
    commit('logoutMutation');
    if(parentUseId&&parentUseId.length > 0) {
      window.localStorage.setItem("parentUseId",JSON.stringify(parentUseId))
    }
    return true;
  },
  /**
   * @Author   Yaodongxin
   * @DateTime 2019-12-12
   * @method   registerAction 注册
   * @param    {function}   options.commit commit
   * @param    {object}   options.user   注册的用户信息
   * @param    {object}   options.self   vue组件对象
   */
  registerAction({ commit, dispatch }, { user, self, flag = false, lang = '' }) {
    return new Promise((resolve, reject) => {
      let registerUser = Object.assign({}, user);
      let loginUser = {};
      registerUser.password = MD5(user.password).toString();
      loginUser.password = registerUser.password;
      loginUser.plainPassword = user.password;
      loginUser.role = user.role;
      // 默认使用邮箱用户名号登录
      loginUser.loginType = 2;
      loginUser.countryCode = user.countryCode;
      loginUser.identify = user.identify
      loginUser.version1 = user.version1
      loginUser.version2 = user.version2

      api.user.register(registerUser).then(({ status, data }) => {
        if (status === 200) {
          let message = !flag ? data.message : '中文学习大礼包领取成功！'
          if (self.currentCountry === 'indonesia') {
            message = 'Berhasil Daftar'
          } else  {
            // 原有注册登录逻辑
            if (!flag) {
              if (window.localStorage.getItem('localeLanguage').toLowerCase()  == 'en') {
                message = 'Successful operation'
              }
            } else {
              // 新转介绍页面逻辑
              if (lang && lang == 'en') {
                message = 'Gift Package for Chinese Learning is successfully received.'
              }
            }
          }
          if (data.code === 200) {
            window.sessionStorage.setItem('parent_id', data.data.userParentInfo.id)
            window.sessionStorage.setItem('parent_token', data.data.jwtToken)
            self.$message({
              message,
              onClose: function() {
                if (self.currentCountry === 'indonesia') {
                  let callback = () => {
                    // self.$router.push("/index");
                  }
                  if (!flag) {
                    dispatch('loginAction', { user: loginUser, self: self, callback })
                  }
                } else {
                  if (self.isPc === false) {
                    self.isSuccess = true
                  } else {
                    let callback = () => {
                      // self.$router.push("/index");
                    }
                    if (!flag) {
                      dispatch('loginAction', { user: loginUser, self: self, callback })
                    }
                  }
                }
              }
            })
          }
          let obj = urlParse()|| {}
          obj.id = data?.data?.userParentInfo?.id
          resolve(data)
          unreport.clickSubmit(obj)
        } else {
          reject('error');
        }
      });
    });
  },
  changePasswordAction({ commit }, { role, userId, newPassword, oldPassword }) {
    return new Promise((resolve, reject) => {
      api.user.changePassword({ role, userId, newPassword, oldPassword }).then(({ status, data }) => {
        if (status === 200 && data.code === 200) {
          resolve();
        } else {
          reject();
        }
      });
    })

  },
  /**
   * @Author   Yaodongxin
   * @DateTime 2020-01-02
   * @method   getParentInfoAction  重新拉家长信息
   * @param    {Function}      options.commit   commit
   * @param    {Number}        userId           家长id
   */
  getParentInfoAction({ commit }, userId) {
    return new Promise((resolve, reject) => {
      api.supplementuser.getParentInfo(userId).then(({ status, data }) => {
        if (status === 200 && data.code === 200) {
          data.data = data.data ? data.data : {};
          commit('updataParentInfo', data.data);
          resolve(data.data);
        } else {
          reject();
        }
      });
    })
  },
  /**
   * @Author   Yaodongxin
   * @DateTime 2020-01-02
   * @method   getTeacherInfoAction  获取教师信息
   * @param    {Function}      options.commit  commit
   * @param    {Number}        userId          教师id
   */
  getTeacherInfoAction({ commit }, userId) {
    return new Promise((resolve, reject) => {
      api.user.getTeacherInfo(userId).then(({ status, data }) => {
        if (status === 200 && data.code === 200) {
          data.data = data.data ? data.data : {};
          commit('teacherInfoMutation', data.data);
          resolve(data.data);
        } else {
          reject();
        }
      });
    })
  },
  /**
   * @Author   Yaodongxin
   * @DateTime 2020-01-03
   * @method   putChildrenAction  更新孩子信息
   * @param    {Function}      options.commit  commit
   * @param    {Object}        params
   */
  putChildrenAction({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.user.putChildren(params).then(({ status, data }) => {
        if (status === 200 && data.code === 200) {
          data.data = data.data ? data.data : {};
          resolve(data.data);
        } else {
          reject();
        }
      });
    })
  },
  /**
   * @Author   Yaodongxin
   * @DateTime 2020-01-06
   * @method   getBriefClassInfoAction 获取学生概要信息，得到学生最新的信息包含余额
   * @param    {Function}      options.commit        commit
   * @param    {Number}      options.parentUserId    家长id
   * @param    {Number}      options.studentUserId   学生id
   */
  getBriefClassInfoAction({ commit }, { parentUserId, studentUserId }) {
    return new Promise((resolve, reject) => {
      api.user.getBriefClassInfo({ parentUserId, studentUserId }).then(({ status, data }) => {
        if (status === 200 && data.code === 200) {
          data.data = data.data ? data.data : {};
          commit('setStudentBriefInfo', data.data);
          resolve(data.data);
        } else {
          reject();
        }
      });
    });
  },
  getSubjectTypeListAction({ commit }) {
    return new Promise((resolve, reject) => {
      api.user.getSubjectTypeList().then(({ status, data }) => {
        if (status === 200 && data.code === 200) {
          commit('setSubjectTypeList', data.data || []);
          resolve(data.data);
        } else {
          reject();
        }
      })
    })
  }
};

const mutations = {
  loginMutation(state, { user, isLogin, jwtToken }) {
    user && (state.user = user);
    isLogin && (state.isLogin = isLogin);
    jwtToken && ( state.jwtToken = jwtToken);
  },
  logoutMutation(state, data) {
    state = {
      user: {
        userParentInfo: {
          balance: 0,
          bonusBalance: 0
        }
      },
      isLogin: false
    };
  },
  updataParentInfo(state, data) {
    state.user.userParentInfo = {
      ...state.user.userParentInfo,
      ...data
    };
    // 更新本地存储信息
    let userInfo = lgLocalStorage('__lgzy__s__');
    userInfo.login_info.user.userParentInfo = state.user.userParentInfo;
    lgLocalStorage('__lgzy__s__', userInfo);
  },
  updateBlance(state, data) {
    // state.user.userParentInfo.balance = state.user.userParentInfo.balance * 1 + data.balance * 1;
    // state.user.userParentInfo.bonusBalance = state.user.userParentInfo.bonusBalance * 1 + data.bonusBalance * 1;
    state.user.userParentInfo.balance = state.user.userParentInfo.balance * 1;
    state.user.userParentInfo.bonusBalance = state.user.userParentInfo.bonusBalance * 1;
    // 更新本地存储信息
    let userInfo = lgLocalStorage('__lgzy__s__');
    userInfo.login_info.user.userParentInfo.balance = state.user.userParentInfo.balance;
    userInfo.login_info.user.userParentInfo.bonusBalance = state.user.userParentInfo.bonusBalance;
    lgLocalStorage('__lgzy__s__', userInfo);
  },
  teacherInfoMutation(state, data) {
    state.teacherInfo = Object.assign({}, state.teacherInfo, data);
  },
  setStudentBriefInfo(state, data) {
    state.studentBriefInfo = Object.assign({}, state.studentBriefInfo, data);
    // 设置user state updateBlance
    // if (data.bonusBalance) {
    //   state.user.userParentInfo.bonusBalance = data.bonusBalance;
    // }
    // if (data.balance) {
    //   state.user.balance = data.balance;
    // }
  },
  setSubjectTypeList(state, data) {
    state.multiSubjectTypeList = data
  },
  setTipType(state, num) {
    state.tipType = num
  }
}

export default { namespaced: true, state, mutations, actions, getters }
