/**
 * 多入口公共模块引入文件
 */
import Vue from 'vue'
import reporter from '@/utils/reporter'
import { LOGTYPE } from '@/utils/reporter'
import Analytics from '@/utils/analytics'

reporter.config({
    appName:"webapp-student",
    environment:LOGTYPE[process.env.VUE_APP_ENV],
    reportUrl: 'https://logan.lingoace.com/logan/web/upload.json',
    errorHandler: function(e) {}
});

Vue.prototype.$report=reporter.report;
Vue.prototype.$Analytics = Analytics
