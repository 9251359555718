var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notice-warpper" }, [
    _c(
      "div",
      { on: { click: _vm.toDownload } },
      [
        _c(
          "el-badge",
          { staticClass: "item", attrs: { value: 0, max: 99, hidden: true } },
          [
            _c("el-avatar", {
              attrs: { size: 50, shape: "square", src: _vm.downloadUrl },
            }),
            _vm._v(" "),
            _c("i", { staticClass: "lg-icon icon-download" }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { on: { click: _vm.toMessageCenter } },
      [
        _c(
          "el-badge",
          { staticClass: "item", attrs: { max: 99, hidden: !_vm.isShow } },
          [
            _c("el-avatar", {
              attrs: { size: 50, shape: "square", src: _vm.noticeUrl },
            }),
            _vm._v(" "),
            _c("i", { staticClass: "lg-icon icon-notice" }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }