import api from '@/api'
const state = {
  currentCountry: '',
  countryNameEN:'',
  countryNameCN:''
}

const mutations = {
  setCurrentCountry (state, data) {
    state.currentCountry = data
  },
  setCountryNameEN (state, data) {
    state.countryNameEN= data
  },
  setCountryNameCN (state, data) {
    state.countryNameCN= data
  }
}

const getters = {
  getCurrentCountry: (state) => state.currentCountry,
  getCountryNameEN: (state) => state.countryNameEN,
  getCountryNameCN: (state) => state.countryNameCN,
}

const actions = {
  getCurrentCountryAction({commit}) {
    return new Promise((resolve, reject) => {
      api.finance.getLocation()
      .then(res => {
        if (res && res.data && res.data.code === 200) {
          commit('setCurrentCountry', res.data.data.toLowerCase())
          resolve(res)
        } else {
          reject('error')
        }
      })
      .catch(err => {
        reject(err)
      })
    })
  },


  getCountryNameENAction({commit}) {
    return new Promise((resolve, reject) => {
      api.finance.getCountryInfo()
      .then(res => {
        if (res && res.data && res.data.code === 200) {
          commit('setCountryNameEN', res.data.data?.enName)
          resolve(res)
        } else {
          reject('error')
        }
      })
      .catch(err => {
        reject(err)
      })
    })
  },

  getCountryNameCNAction({commit}) {
    return new Promise((resolve, reject) => {
      api.finance.getCountryInfo()
      .then(res => {
        if (res && res.data && res.data.code === 200) {
          commit('setCountryNameCN', res.data.data.cnName)
          resolve(res)
        } else {
          reject('error')
        }
      })
      .catch(err => {
        reject(err)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
