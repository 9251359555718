var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "div",
        { staticStyle: { "min-height": "100%" } },
        [_vm.islogin ? _c("index") : _vm._e()],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "copyright" }, [
        _vm._v("Copyright © 2017-" + _vm._s(_vm.year) + " LingoAce"),
      ]),
      _vm._v(" "),
      _c("NavigatorTip"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }