var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "user-warpper" },
    [
      _c(
        "el-dropdown",
        { attrs: { "hide-on-click": false } },
        [
          _c("span", { staticClass: "userinfo" }, [
            _c("span", { staticClass: "name" }, [
              _vm._v("\n        " + _vm._s(_vm.username) + "\n        "),
              _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            {
              staticClass: "dropdown",
              attrs: { slot: "dropdown" },
              slot: "dropdown",
            },
            [
              _c(
                "el-dropdown-item",
                {
                  nativeOn: {
                    click: function ($event) {
                      return _vm.jump.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Profile")))]
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-item",
                {
                  nativeOn: {
                    click: function ($event) {
                      return _vm.jumpOrder.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "dropdown-order-box" }, [
                    _c("div", [_vm._v(_vm._s(_vm.$t("My Order")))]),
                    _vm._v(" "),
                    _vm.pendingOrderCount > 0
                      ? _c("div", { staticClass: "dropdown-order-ball" })
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-item",
                {
                  nativeOn: {
                    click: function ($event) {
                      _vm.isOpen2 = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Change Password")))]
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-item",
                {
                  nativeOn: {
                    click: function ($event) {
                      _vm.isOpen = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Sign Out")))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.pendingOrderCount > 0
            ? _c("div", { staticClass: "order-tips-box" }, [
                _c("div", { staticClass: "order-delta" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "order-tips",
                    on: { click: _vm.goToOrderCenter },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("You have")) +
                        " " +
                        _vm._s(_vm.pendingOrderCount) +
                        " " +
                        _vm._s(_vm.$t("Pending order"))
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "m-alert-dialog-with-title",
          attrs: {
            title: _vm.$t("Sign Out"),
            center: true,
            "show-close": false,
            visible: _vm.isOpen,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isOpen = $event
            },
          },
        },
        [
          _c("div", { staticClass: "c-tips f18" }, [
            _vm._v(_vm._s(_vm.$t("Confirm to sign out"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  staticClass: "w160",
                  on: {
                    click: function ($event) {
                      _vm.isOpen = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "w160",
                  attrs: { type: "primary", loading: _vm.logoutLoading },
                  on: {
                    click: function ($event) {
                      return _vm.logout()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("Change Password"),
            visible: _vm.isOpen2,
            width: "400px",
            "show-close": false,
            center: true,
            "destroy-on-close": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isOpen2 = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-wrap" },
            [
              _c(
                "el-form",
                {
                  ref: "passwordForm",
                  attrs: {
                    "label-position": "left",
                    rules: _vm.rule,
                    model: _vm.form,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "mb30",
                      attrs: { label: "", prop: "oldPassword" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("Enter old password"),
                          type: "password",
                        },
                        model: {
                          value: _vm.form.oldPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "oldPassword", $$v)
                          },
                          expression: "form.oldPassword",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "mb30",
                      attrs: { label: "", prop: "newPassword" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("Set password"),
                          type: "password",
                        },
                        model: {
                          value: _vm.form.newPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "newPassword", $$v)
                          },
                          expression: "form.newPassword",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "checkPassword" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("Enter password again"),
                          type: "password",
                        },
                        model: {
                          value: _vm.form.checkPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "checkPassword", $$v)
                          },
                          expression: "form.checkPassword",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { staticClass: "w160", on: { click: _vm.close } },
                [_vm._v(_vm._s(_vm.$t("CANCEL")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "w160",
                  attrs: { type: "primary" },
                  on: { click: _vm.changePassword },
                },
                [_vm._v(_vm._s(_vm.$t("CONFIRM")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }