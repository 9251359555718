<template>
  <el-row class="lg-container main">
    <el-col :span="3" class="left" :style="positionFixed">
      <div class="students-warpper" v-if="students !== null">
        <Students />
      </div>
      <div class="no-students-wrapper" v-else  v-loading>
      </div>
      <el-card :body-style="{ padding: '0px', width:'' }" class="left-menu-warpper">
        <LeftMenu @selectMenu="selectMenu" />
      </el-card>

      <el-card :body-style="{ padding: '5px 20px' }" class="it-test-warpper">
        <i class="notice-ittest" v-if="isShowHot"></i>
        <a :href="`https://global.talk-cloud.net/static/h5_new_devicetest/index.html#/?domain=oks&isDeviceCallback=1&pid=${currentStudent.id}`" target="_blank">
          <i class="mg-icon icon-ittest"></i>
          <span class="title" @click="getIsShowHot">{{$t("IT Test")}}</span>
        </a>
      </el-card>
      <el-card :body-style="{ padding: '13px 12px' }" class="friend-warpper">
        <a href="javascript:;" @click="changeRouteRef">
          <i class="mg-icon icon-friend"></i>
          <span class="title">{{$t("Refer friend")}}</span>
        </a>
      </el-card>
    </el-col>
    <!-- <el-col :span="19"> -->
    <el-col :span="19" class="right">
      <!-- <transition :name="transitionName"> -->
      <transition name="slide-fade">
        <router-view class="child-view" />
      </transition>
      <AddStudent
        @close="isOpen = false"
        :open="isOpen"
        :info="{parentId: getUserInfo.userParentInfo.id}"
      ></AddStudent>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex";
import LeftMenu from "./LeftMenu";
import Students from "../components/Students";
import AddStudent from "@/components/plugin/AddStudent";
import MD5 from "crypto-js/md5";
import Gtag from '@/utils/gtag';
export default {
  components: { LeftMenu, Students, AddStudent },
  data() {
    return {
      transitionName: "slide-left",
      isOpen: false,
      screenWidth: "",
      positionFixed: "",
      topfixed: "",
      isShowHot: true,
      isRouterAlive: true
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUserInfo",
      currentStudent: "student/getCurrentStudent",
      students: "student/students",
      getUserInfo: "user/getUserInfo",
      isNewUser: "student/getIsNewUser"
    }),
    // tkUrl() {
    //   var key = "ex5lZksGvEmoeC1m";
    //   var ts = Date.parse(new Date());
    //   var serial = 1419683487;
    //   var usertype = 2;
    //   var auth = MD5(key + ts + serial + usertype);
    //   var domain = "oks";
    //   var username = this.currentStudent.realName;
    //   var url = `https://global.talk-cloud.net/WebAPI/entry?serial=${serial}&username=${username}&usertype=2&ts=${ts}&auth=${auth}&domain=${domain}`;
    //   return url;
    // }
  },
  created() {
    // this.leftfixed = this.leftFixed;
    this.windowResizeEvent();
  },
  mounted() {
    if (this.user.userParentInfo.balance < 5 && !this.isNewUser) {
      this.topFixed = 220;
    }
    this.getParentUseId()
  },
  watch: {
    $route(to, from) {
      if (to.meta.index > from.meta.index) {
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }
    }
  },
  methods: {
    MD5,
    windowResizeEvent(callback) {
      this.topfixed = this.topFixed;
      let screenWidth = document.body.clientWidth;
      if (screenWidth > 1200) {
        this.positionFixed =
          "position:fixed;left:" +
          (screenWidth - 1200) / 2 +
          "px;top:" +
          this.topfixed +
          "px;z-index:3";
        // console.log(this.positionFixed);
      } else {
        this.positionFixed = "";
      }
      let that = this;
      window.onresize = function() {
        screenWidth = document.body.clientWidth;
        // console.log(2, this.screenWidth, that.topfixed);
        if (screenWidth > 1200) {
          that.positionFixed =
            "position:fixed;left:" +
            (screenWidth - 1200) / 2 +
            "px;top:" +
            that.topfixed +
            "px;z-index:2";
          // console.log(that.positionFixed);
        } else {
          that.positionFixed = "";
        }
      };
    },
    changeRouteRef() {
      this.$Analytics.eventLog('查看邀请好友页面左导航');
      this.$router.push({
        name: "referfriends"
      });
    },
    selectMenu(path) {
      if (path != "/home") {
        if (this.students !== null) {
          if (!this.currentStudent.realName) {
            // 打开添加孩子弹窗
            this.isOpen = true;
          }
          // else if (this.currentStudent.courseEditionId === null && this.currentStudent.courseLevel === null) {
          //   let pathname = location.pathname;
          //   location.href = `${pathname}questionnaire.html?uid=${this.$store.state.user.user.userParentInfo.id}&id=${this.$store.state.student.currentStudent.id}&from=pc`;
          //   return false;
          // }
          else {
            this.$router.push(path);
          }
        }
      } else {
        this.$router.push(path);
      }
    },
    getParentUseId() {
      let data = []
      let parentUseId = JSON.parse(window.localStorage.getItem("parentUseId"))
      let loginParentUseId = JSON.parse(window.localStorage.getItem("__lgzy__s__"))
      if(parentUseId != null) {
        if(parentUseId.length > 0) {
          parentUseId.forEach( item => {
            if( item == loginParentUseId.login_info.user.userParentInfo.id) {
              this.isShowHot = false
            }
          });
        }
      } else{
        window.localStorage.setItem("parentUseId",JSON.stringify(data))
      }
    },
    getIsShowHot() {
      let loginParentUseId = JSON.parse(window.localStorage.getItem("__lgzy__s__")).currentStudentInfo.studentInfo.parentUserId
      let parentUseId = JSON.parse(window.localStorage.getItem("parentUseId"))
      if(this.isShowHot) {
        parentUseId.push(loginParentUseId)
        this.isShowHot = false
        window.localStorage.setItem("parentUseId",JSON.stringify(parentUseId))
        Gtag.webHome_click({
          eventName: '首页_设备检测入口点击',
          name: '首页_设备检测入口点击',
          parentId: this.user.userParentInfo.id,
          studentId: this.currentStudent.id

        })
        this.$Analytics.eventLog('首页_设备检测入口点击')
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.no-students-wrapper {
  width 200px
  height 150px
  box-shadow 0 2px 8px rgba(182,182,182,0.12)
}
.title {
  font-size: 20px;
  font-family: 'SofiaProSoftMedium',Themegeneral;
  font-weight: bold;
}
.it-test-warpper {
  position relative;
}
.notice-ittest {
  display: inline-block;
  position: absolute;
  top:3px;
  right: 6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: red;
}
</style>
