<template>
  <div class="index-warpper">
    <div class="l-header">
      <TopBar />
      <TopMenu @selectMenu="selectMenu" />
    </div>
    <div class="content">
      <!-- 发送邮件弹窗 -->
      <div class="tip" v-if="tipType == 1 && $route.path.indexOf('/home') == 0">
        <span>{{$t("Your Policy Email was resent!")}}</span>
        <img @click="colseTip" class="colse-img" src="~@/assets/theme-default/images/index/close-img.png" alt="">
      </div>
      <!-- 删除账户全局弹窗 -->
      <div class="tip del" v-if="tipType == 2">
        <img src="~@/assets/theme-default/images/usercenter/ic.png" alt="" class="icon">
        <span >{{$t("Your account is pending deletion")}}</span>
      </div>
      <div class="tip-warpper">
        <ToUpTip v-if="user.userParentInfo.balance < 5 && !isNewUser" />
      </div>
      <div class="main-warpper">
        <router-view></router-view>
      </div>
      <AddStudent @close="isOpen = false" :open="isOpen" :canClose="!!this.currentStudent.realName"></AddStudent>
    </div>
    <BackToTop></BackToTop>
  </div>
</template>

<script>
import TopBar from "./components/Top";
import TopMenu from "./components/TopMenu";
import ToUpTip from "./components/ToUpTip";
import LeftMenu from "./components/LeftMenu";
import Main from "./components/Main";
import AddStudent from "@/components/plugin/AddStudent";
import BackToTop from "@/components/common/BackToTop";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { lgLocalStorage } from '../../common/js/store';
export default {
  data() {
    return {
      isOpen: false,
      pageIsLoaded: false
    };
  },
  components: {
    TopMenu,
    TopBar,
    ToUpTip,
    LeftMenu,
    Main,
    AddStudent,
    BackToTop
  },
  computed: {
    ...mapGetters({
      user: "user/getUserInfo",
      currentStudent: "student/getCurrentStudent",
      isNewUser: "student/getIsNewUser",
      students: "student/students"
    }),
    storeCurrentStudentId: function() {
      return this.$store.state.student.currentStudent.id;
    },
    tipType() {
      return this.$store.state.user.tipType == 0 ? (!!localStorage.getItem("tipType") ? localStorage.getItem("tipType") : 0  ):this.$store.state.user.tipType
    }
  },
  watch: {
    storeCurrentStudentId: function(newId, oldId) {
      this.setStudentRole(newId);
      this.getJoinedClass(newId);
    }
  },
  methods: {
    ...mapActions({
      getParentInfoAction: "user/getParentInfoAction",
      getChildren: "student/getChildren",
      setStudentRole: "student/setStudentRole",
      getJoinedClass: "student/getJoinedClass",
      getSubjectTypeListAction: "user/getSubjectTypeListAction",
    }),
    ...mapMutations({
      'setTipType': 'user/setTipType'
    }),
    selectMenu(path) {
      if (!this.pageIsLoaded) {
        return
      }
      if (path != "/home") {
        if (!this.currentStudent.realName) {
          // 打开添加孩子弹窗
          this.isOpen = true;
        }
        // else if (this.currentStudent.courseEditionId === null && this.currentStudent.courseLevel === null) {
        //   let pathname = location.pathname;
        //   location.href = `${pathname}questionnaire.html?uid=${this.$store.state.user.user.userParentInfo.id}&id=${this.$store.state.student.currentStudent.id}&from=pc`;
        //   return false;
        // }
        else {
          this.$router.push(path);
        }
      } else {
        this.$router.push(path);
      }
    },
    // amplitude用户属性
    setUserProperties() {
      const loginInfo = lgLocalStorage("__lgzy__s__")?.login_info?.user;
      const curStudentInfo = lgLocalStorage("__lgzy__s__")?.currentStudentInfo?.studentInfo;
      const userProperties = {
        'role': 'parent',
        'sign_up_country': loginInfo?.userParentInfo?.nationality,
        'parent_id': loginInfo?.userParentInfo?.id,
        'age': new Date().getFullYear() - curStudentInfo?.birthday.slice(0, 4),
        'student_id': curStudentInfo?.id,
        'gender': (()=>{
          let gender = 'unknown';
          if(curStudentInfo?.gender == 1){
            gender = 'male';
          }else if(curStudentInfo?.gender == 2){
            gender = 'female';
          }
          return gender;
        })(),
        'student_name': curStudentInfo?.realName,
        'language': loginInfo?.userParentInfo?.language,
        'paid': Boolean(loginInfo?.isPayUser),
        'wallet_size': loginInfo?.userParentInfo?.balance
      }
      this.$Analytics.userLog(userProperties)
    },
    colseTip() {
      this.setTipType(0)
      localStorage.setItem("tipType", this.$store.state.user.tipType);
    },
    getDelRecord() {
      this.$api.common.delRecord().then( res => {
        if(res.data.code == 200) {
          if(!!res.data.data) {
            this.setTipType(2)
          }else {
            this.setTipType(0)
          }
          localStorage.setItem("tipType", this.$store.state.user.tipType);
        }
      })
    }
  },
  beforeMount () {
    // amplitude设置userId
    this.$Analytics.setUserId(this.user.userParentInfo?.id || null);
  },
  mounted() {
    // 初始化需要的数据
    //获取家庭孩子信息
    this.getChildren({
      parentId: this.$store.state.user.user.userParentInfo.id,
      isAdd: 0
    }).then(
      data => {
        this.pageIsLoaded = true
        console.log(this.students, this.currentStudent.realName)
        // let student={}
        // this.students.forEach(item => {
        //   if(item.realName==this.currentStudent.realName){
        //     student=JSON.stringify(item)
        //   }
        // });
        // sessionStorage.setItem("student",student)
        if (this.students != null && !this.currentStudent.realName) {
          // 打开添加孩子弹窗
          this.isOpen = true;
        }
        // amplitude设置用户属性
        this.setUserProperties();
      },
      () => {
        window.sessionStorage.setItem('referPath', document.referrer);
        this.$message({
          message: "Failed to get children's data"
        });
      }
    );
    this.$api.user.getUploadConfig()
    .then(res => {
      if (res.status === 200 && res.data.code === 200) {
        lgLocalStorage('__lgzy__upload__config__',{
          clientConfig: res.data.data
        })
      }
    })
    // 获取学科类型信息
    this.getSubjectTypeListAction()
    this.getDelRecord()
  }
};
</script>

<style lang="stylus" scoped>
.tip {
  height: 70px;
  font-weight: 700;
  font-size: 20px;
  line-height: 70px;
  margin-top: -20px;
  text-align: center;
  color: #fff;
  background: #FEA03D;
}
.colse-img {
  position: absolute;
  right: 44px;
  top: 28px;
  width: 14px;
  cursor: pointer;
}
.del {
  background: #86D6D7;
  span {
    vertical-align: super;
  }
}

.icon {
  width: 28px;
  height: 30px;
}
</style>
