import GetData from '@/utils/axios.js'
const { $get, $post, $put} = new GetData()

const checkLoginTip = function() {
  return $get(`/api/user/logintips`)
}

const getPayBanner = function(area, terminalChannel) {
  return $get(`/api/activity/banner/${area}/${terminalChannel}`)
}

// 查询LingoAce相关协议
const getPrivacypolicy = function() {
  return $get(`/api/common/v2/policy`)
}
// 用户同意LingoAce相关协议记录-v2
const updatePrivacypolicy = function(params) {
  return $post(`/api/user/v2/policy/status`, params)
}
// 查询当前登录用户的详细信息
const getuserInfoRole = function ({role}) {
  return $get(`/api/user/logining/user/info/${role}`)
}
// banner图管理
const getBannerChange = function (code) {
  return $get(`/api/activity/image/source/${code}`)
}
// nps 获取问卷信息
const getSurvey = function (version, terminal) {
  return $get(`/campaign/survey/getsurvey/${version}/${terminal}`)
}
// 保存nps问卷消息
const savesurvey = function(params) {
  return $post(`/campaign/survey/savesurvey`, params)
}
// nps问卷事件上报
const surveyUploadEvent = function(params) {
  return $post(`/campaign/survey/uploadEvent/v2`, params)
}
// 清除问卷接口
const clearSurvey = function () {
  return $get(`/api/clear-parent-surveyinfo`)
}
const buriedPoint = function (params) {
  return $post(`/program/course/homework/preview/buried/point`,params)
}
const sendEmail = function () {
  return $get(`/api/coppa/send/email`)
}
const CCLAAreaInfo = function () {
  return $get(`/api/coppa/user/cc/la/area/info`)
}
const authStatus = function (params) {
  return $put(`/api/coppa/edit/auth/status`,params)
}
// C端发起删除申请
const delAccount = function () {
  return $get(`/api/coppa/delete/account/apply`)
}
// 查询用户删除申请记录
const delRecord = function () {
  return $get(`/api/coppa/get/apply/record`)
}
// 修改设备语言 参数固定
const setDeviceLang = function () {
  return $post(`/api/app/version/device/language?clientFlag=web`)
}
export default {
  checkLoginTip,
  getPayBanner,
  getPrivacypolicy,
  updatePrivacypolicy,
  getBannerChange,
  getSurvey,
  savesurvey,
  surveyUploadEvent,
  clearSurvey,
  getuserInfoRole,
  buriedPoint,
  sendEmail,
  CCLAAreaInfo,
  authStatus,
  delAccount,
  delRecord,
  setDeviceLang
}
