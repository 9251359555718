<template>
  <ul class="lg-container topmenu">
    <li class="logo">
      <a @click="selectMenu('/home')" href="javascript:;">
        <img :src="logoUrl" alt />
      </a>
    </li>
    <li class="title" @click="selectMenu('/home')" :class="{'active':$route.path.indexOf('/home') == 0}">{{$t('Study')}}</li>
    <li class="points" @click="selectMenu('/points')" :class="{'active':$route.path.indexOf('/points') == 0}" style="height: 100%;">
      <!-- <div> -->
        <!-- <i class="icon"></i> -->
        <!-- <span class="point">{{totalPoints}} {{$t('Points')}}</span> -->
        <!-- <span class="point">{{$t('Points')}}</span> -->
        {{$t('Points')}}
      <!-- </div> -->
    </li>
    <li
      class="refer-friends"
      @click="selectMenu('/referfriends')"
      :class="{'active':$route.path.indexOf('/referfriends')==0  || $route.path.indexOf('/aceTip') == 0 }"
      ><div :class="['refer-friends-icon', $route.path.indexOf('/referfriends')==0  || $route.path.indexOf('/aceTip') == 0  ? 'refer-friends-icon-active' :'refer-friends-icon-normal']"></div>{{$t('Refer Friends')}}</li>
  </ul>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Gtag from '@/utils/gtag';

export default {
  data() {
    return {
      logoUrl: require("@/assets/theme-default/images/index/logo@2x.png"),
      routerParent: '',
      totalPoints: 0,
      countryNameEN:'',
      countryNameCN:'',
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/getUserInfo',
      getCountrynameEN: "location/getCountryNameEN",
      getCountrynameCN: "location/getCountryNameCN",
    }),
    balance() {
      let user = this.user;
      return user.userParentInfo.balance * 1 + user.userParentInfo.bonusBalance * 1 + user.userParentInfo.sgBalance * 1;
    }
  },
  watch: {
    '$route': {
      handler(toRouter, fromRouter) {
        this.routerParent = toRouter.path.split('/')[1];
        // console.log(this.routerParent)
      }

    }
  },
  mounted() {
    this.getBalanceCount()
    this.$EventBus.$on("onGetBalanceCount", () => {
      setTimeout(() => {
        this.getBalanceCount()
      })
    })
  },
  created() {
    Promise.all([this.getCountryNameENAction(),this.getCountryNameCNAction()])
    .then((values) => {
      Gtag.appGtagJs_show({
        eventName:'登录后曝光页面',
        name:'曝光_referfriends',
        userId:this.user.userParentInfo.id,
        countryNameEN:this.getCountrynameEN,
        countryNameCN:this.getCountrynameCN,
      })
      this.$Analytics.eventLog('曝光_referfriends')
    });
  },
  methods: {
    ...mapActions({
      getCountryNameENAction: 'location/getCountryNameENAction',
      getCountryNameCNAction: 'location/getCountryNameCNAction'
    }),
    jumpHome() {
      this.$router.push('/');
    },
    selectMenu(path) {
      this.$emit('selectMenu', path);
      if(path==='/referfriends'){
        // Gtag.axiosEvent({userId:this.user.userParentInfo.id});
        // console.log(this.getCountrynameEN)
        // console.log(this.getCountrynameCN)
        Gtag.appLgGtagJs({
          eventName:'点击referfriends按钮',
          name:'share',
          userId:this.user.userParentInfo.id,
          countryNameEN:this.getCountrynameEN,
          countryNameCN:this.getCountrynameCN
          });
        this.$Analytics.eventLog('点击referfriends按钮')
      }
    },
    getBalanceCount () {
      const userId = this.user.userParentInfo.id
      this.$api.finance.getAccountLessons({ userId }).then(res => {
        if (res && res.data && res.data.code === 200) {
          this.totalPoints = res.data.data
        } else {
          this.$message({
            message: res.data.message,
            center: true
          });
        }
      }, err => {
        console.log('err', err)
      })
    },
  }
};

</script>
