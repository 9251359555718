var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-up-tip lg-container" },
    [
      _vm._v(
        "\n  " + _vm._s(_vm.$t("Your account balance is less than")) + "\n  "
      ),
      _c("span", { staticClass: "red" }, [_vm._v("5")]),
      _vm._v(
        "\n  " +
          _vm._s(
            _vm.$t("points. In order to learn Chinese in succession, please")
          ) +
          "\n  "
      ),
      _c("span", { staticClass: "red" }, [_vm._v(_vm._s(_vm.$t("Top-up")))]),
      _vm._v("\n  " + _vm._s(_vm.$t("in time ~")) + "\n  "),
      _c("el-button", { attrs: { type: "danger" }, on: { click: _vm.jump } }, [
        _vm._v(_vm._s(_vm.$t("TOP-UP"))),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }