<!-- ✅ -->
<template>
  <div class="order-center">
    <div class="l-section">
      <div class="tab-item" :class="{'tab-item-active': currentTab == 'myOrder'}" @click="currentTab = 'myOrder'">
        <img src="@/images/order/order.png" v-if="currentTab == 'classDetail'">
        <img src="@/images/order/order_active.png" v-else>
        {{$t('My Order')}}
      </div>
      <div class="tab-item" :class="{'tab-item-active': currentTab == 'classDetail'}" @click="currentTab = 'classDetail'">
        <img src="@/images/order/money.png" v-if="currentTab == 'myOrder'">
        <img src="@/images/order/money_active.png" v-else>
        {{$t('Transactions')}}
      </div>
    </div>
    <div class="r-section">
      <OrderList v-if="currentTab == 'myOrder'"></OrderList>
      <ClassDetail v-if="currentTab == 'classDetail'"></ClassDetail>
    </div>
  </div>
</template>
<script>
import ClassDetail from '../../auto-components/Order/ClassDetail'
import OrderList from '../../auto-components/Order/OrderList'
export default {

  name: 'orderCenter',

  data() {
    return {
      currentTab: 'myOrder',
    }
  },

  created() {
    this.currentTab = this.$route.query.tab == 'points' ? 'classDetail' : 'myOrder'
  },

  mounted() {},

  methods: {},

  components: {
    ClassDetail,
    OrderList
  }

}

</script>
<style scoped lang="stylus">
.order-center {
  width: 1200px;
  margin: 0 auto;
  min-height: 715px;
  display: flex;
  justify-content: space-between;

  .l-section {
    width: 200px;
    height: 715px;
    background: #fff;
    box-shadow: 0px 4px 8px 4px rgba(182, 182, 182, 0.08);
    border-radius: 8px;
    padding-top: 12px;

    .tab-item {
      height: 60px;
      /*text-align: center;*/
      padding-left: 30px;
      color: #34364D;
      cursor: pointer;
      padding-top: 6px;

      &.tab-item-active {
        color: #fff;
        background: #84D6D6;
      }

      img {
        height: 48px;
        width: 48px;
        vertical-align: middle;
      }
    }
  }

  .r-section {
    width: 940px;
  }
}

</style>
