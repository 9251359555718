var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "lg-gender" }, [
    _c(
      "i",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show == 3 || _vm.show == 1,
            expression: "show==3 || show==1",
          },
        ],
        staticClass: "boy",
        class: { active: 1 == _vm.gender },
        on: {
          click: function ($event) {
            return _vm.choose(1)
          },
        },
      },
      [_vm._v(_vm._s(_vm.isIndonesia ? "Laki-laki" : _vm.$t("BOY")))]
    ),
    _vm._v(" "),
    _c(
      "i",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show == 3 || _vm.show == 2,
            expression: "show==3 || show==2",
          },
        ],
        staticClass: "girl",
        class: { active: 2 == _vm.gender },
        on: {
          click: function ($event) {
            return _vm.choose(2)
          },
        },
      },
      [_vm._v(_vm._s(_vm.isIndonesia ? "Perempuan" : _vm.$t("GIRL")))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }