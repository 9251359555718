import GetData from '@/utils/axios.js'
const { $get, $post } = new GetData();

// 课包信息
const subjectList = function(studentUserId) {
    return $get(`/api/multi/student/subject/list?studentUserId=${studentUserId}`)
}

export default {
    subjectList,
}