var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order-center" }, [
    _c("div", { staticClass: "l-section" }, [
      _c(
        "div",
        {
          staticClass: "tab-item",
          class: { "tab-item-active": _vm.currentTab == "myOrder" },
          on: {
            click: function ($event) {
              _vm.currentTab = "myOrder"
            },
          },
        },
        [
          _vm.currentTab == "classDetail"
            ? _c("img", { attrs: { src: require("@/images/order/order.png") } })
            : _c("img", {
                attrs: { src: require("@/images/order/order_active.png") },
              }),
          _vm._v("\n      " + _vm._s(_vm.$t("My Order")) + "\n    "),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tab-item",
          class: { "tab-item-active": _vm.currentTab == "classDetail" },
          on: {
            click: function ($event) {
              _vm.currentTab = "classDetail"
            },
          },
        },
        [
          _vm.currentTab == "myOrder"
            ? _c("img", { attrs: { src: require("@/images/order/money.png") } })
            : _c("img", {
                attrs: { src: require("@/images/order/money_active.png") },
              }),
          _vm._v("\n      " + _vm._s(_vm.$t("Transactions")) + "\n    "),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "r-section" },
      [
        _vm.currentTab == "myOrder" ? _c("OrderList") : _vm._e(),
        _vm._v(" "),
        _vm.currentTab == "classDetail" ? _c("ClassDetail") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }