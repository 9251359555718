export default {
  /**
   * rechargeSuccessCode	充值成功
   */
  rechargeSuccessCode: 3102,
  /**
   * moneyLittleCode	余额不足
   */
  moneyLittleCode: 3103,
    /**
   * classReminderSettingsCode	上课提醒设置
   */
  classReminderSettingsCode: 3104,
  /**
   * classRemindCode	上课提醒
   */
  classRemindCode: 1101,
  /**
   * firstClassFeedbackCode 首课反馈
   */
  firstClassFeedbackCode: 1110,
  /**
   * unitReportCode 单元报告
   */
  unitReportCode: 1111,
  /**
   * loginChinaCode 中国区手机号登录流程优化
   */
  loginChinaCode: 3105,
  /**
   * notificationoflessoncancellation 取消约课提示
   */
  notificationoflessoncancellation: 4015,
  /**
   * failedtopay 未如期付款提示
   */
  failedtopay: 3151
}
