<template>
  <span class="lg-gender">
    <i class="boy" v-show="show==3 || show==1" @click="choose(1)" :class="{'active':1 == gender}">{{isIndonesia ? 'Laki-laki' : $t("BOY")}}</i>
    <i class="girl" v-show="show==3 || show==2" @click="choose(2)" :class="{'active':2 == gender}">{{isIndonesia ? 'Perempuan' :$t("GIRL")}}</i>
  </span>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    };
  },
  props: {
    gender: {
      default: 1,
      type: [String, Number]
    },
    readyonly: {
      default: false,
      type: Boolean
    },
    show: {
      default: 3,
      type: [String, Number]
    },
    isIndonesia: {
      default: false,
      type: Boolean
    },
  },
  methods: {
    choose(flag) {
      if (!this.readyonly) {
        this.$emit("change", flag);
      }
    }
  }
};
</script>
<style lang='stylus' scoped>
.lg-gender {
  display: inline-block;

  i {
    display: inline-block;
    width: 80px;
    height: 28px;
    cursor: pointer;
    opacity: 1;

    &.boy,
    &.girl {
      color:#fff;
      border-radius:15px;
      font-style: normal;
      text-align:center;
      line-height:30px;
    }
    &.boy{
      background: #dff2f5;
      border: 1px solid #dff2f5;
    }
    &.girl {
      background: #fbddd7;
      border: 1px solid #fbddd7;
    }

    &.active.boy {
      background: #84D6D6;
      border: 1px solid #84D6D6;
      color:#fff;
      opacity: 1;
    }
    &.active.girl {
      background: #F0899D;
      border: 1px solid #F0899D;
      color:#fff;
      opacity: 1;
    }
    &:nth-child(2){
      margin-left: 20px;
    }
  }
}
</style>