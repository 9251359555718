var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _vm._v(_vm._s(_vm.$t("Time remaining")) + ": "),
      _c("span", { staticStyle: { color: "#F891A5" } }, [
        _vm._v(_vm._s(_vm.countDown(_vm.endDate))),
      ]),
      _vm.page == "detail"
        ? _c("span", [
            _vm._v(" " + _vm._s(_vm.$t("or the order will be cancelled"))),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }