import Logan from 'logan-web';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { getWindowInfo, getPlatformInfo, getLanguage } from "@lingoace/environment-lingoace";

dayjs.extend(timezone);
let environment = "";
let appName = "";

function config(config) {
  environment = config.environment;
  appName = config.appName;
  Logan.initConfig(config)
}
export const LOGTYPE = {
  development: 1,
  pre_production: 2,
  production: 3
}

function isStringAndEmptyChat(value) {
  return typeof value === "string" &&
    value !== "";
}
// 家长id_学生id
function getUnionId() {
  let lingoace = JSON.parse(localStorage.getItem('__lgzy__s__'))
  if (lingoace) {
    let parentid=lingoace?.login_info?.user?.userParentInfo.id;
    let sid=lingoace?.currentStudentInfo?.studentInfo.id;
    return {
      parentid,
      sid,
      unionId:`${parentid}_${sid}`
    }
  }else {
    return new Date().getTime();
  }
}

function report(logContent,unionId) {
  if (!isStringAndEmptyChat(appName)) {
    throw new Error('初始化 appName');
  }
  let info = getUnionId();
  let windowInfo=getWindowInfo();
  unionId = unionId||info.unionId;
  Logan.log(JSON.stringify({
    url: window.location.href,
    log: {
      parentId:info.parentid,
      studentId:info.sid,
      referrer:document.referrer,
      ...logContent},
    clientTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    clientZone: dayjs.tz.guess(),
    ua: getPlatformInfo(),
    pixelRatio: windowInfo.pixelRatio,
    screenWidth: windowInfo.screenWidth,
    screenHeight: windowInfo.screenHeight,
    windowWidth: windowInfo.windowWidth,
    windowHeight: windowInfo.windowHeight,
    language: getLanguage(),
  }), environment);
  Logan.report({
    fromDayString: dayjs().add(-6, 'day').format('YYYY-MM-DD'),
    toDayString: dayjs().format('YYYY-MM-DD'),
    deviceId: appName + "-" + unionId,
    unionId: unionId,
    incrementalReport: true,
  });
}
export default {
  config,
  report
}
