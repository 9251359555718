import GetData from '@/utils/axios.js'
const { $get, $post } = new GetData()
const submitQuestionnaire = function(params) {
  return $post('/api/course/questionnaire/result', params)
}
// 闪卡预习入口埋点上报
const shankaClickReport = function(data) {
  return $post('/api/v1/data-transfer/report/app/preview_class_event', data)
}
// 课包信息
const packageList = function () {
  return $get('/api/multi/user/package/list')
}

// 获取家长名下的白名单
const getWhiteList = function (params) {
  const p = { ...params }
  const parentUserId = p.parentUserId;
  delete p.parentUserId;
  return $get(`/api/classmanage/white/schedule/learning/plan/parent/${parentUserId}`, p)
}

// 家长添加版本升级白名单
const submitWhiteList = function (params) {
  return $post(`/api/classmanage/white/schedule/learning/plan/parent`, params)
}

const getPlanStudentList = function (params) {
  return $get(`/api/student/plan/list`, params)
}

export default {
  submitQuestionnaire,
  shankaClickReport,
  packageList,
  getWhiteList,
  submitWhiteList,
  getPlanStudentList,
}
