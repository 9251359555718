import GetData from '@/utils/axios.js'
const { $get, $post } = new GetData()

/**
 * 查询试听课反馈试卷的模板
 * language     {string} 语言
 */
const JudgeLanguage = function(language) {
  return $get(`/api/parent/feedback/webapi/getSurvey/${language}`)
}

/**
 * 添加试听课反馈信息
 * @param {Object} params 参数对象，包括：
 * userId           {long}    家长id
 * surveyId         {int}     试卷id
 * studentId        {long}    学生id
 * courseEditionId  {int}     版本id
 * courseLevel      {int}     级别
 * unitNo           {int}     单元序号
 * lessonNo         {int}     lesson序号
 * classRoomId      {int}     拓课运roomid
 * json             {json}    试卷内容
 */
const AddTrialLesson = function(params) {
  return $post(`/api/parent/feedback/feedback/submit`,params)
}

/**
 * 判断是否可以进行试听课反馈
 * @param {Object} params 参数对象，包括：
 * userId           {long}    家长id
 * classTypeId      {long}    课堂类型 1：1v1, 2：小班课， 3：大班课
 * courseEditionId  {int}     版本id
 * courseLevel      {int}     级别
 */
const JudgmentTrialClass = function(params) {
  return $post(`/api/parent/feedback/feedback/checkAuditionFeedback`,params)
}

export default {
  JudgeLanguage,
  AddTrialLesson,
  JudgmentTrialClass
}
