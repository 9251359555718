import { createAnalytics } from "@pplingo/analytics-lingoace"
import { lgLocalStorage } from "@/common/js/store"
let analytics = createAnalytics();
let userId = lgLocalStorage('__lgzy__s__')?.login_info?.user?.userParentInfo?.id || null;
let API_KEY;
switch (process.env.NODE_ENV){
  case 'production':
    API_KEY = '4ee74abb84fd6822be2abddf3496e56a';
    break;
  default:
    API_KEY = 'd89ea7819b1d835cf8146f1e28cc9f10';
}
// 初始化 analytics
analytics.init({
  API_KEY,
  userId,
  config:{
    apiEndpoint:'//api2.amplitude.com/',
  }
});

/**
 * 设置userId
 * @param {String} userId
 */
const setUserId = (userId) => {
  analytics.setUserId(userId);
}

/**
 * 发送事件
 * @param {String} event
 * @param {Object} eventProperties
 * @param {Function} cb
 */
const eventLog = (event, eventProperties, cb) => {
  analytics.logEvent({
    event,
    eventProperties
  }, cb);
}

/**
 * 设置用户属性
 * @param {Object} userProperties
 * @param {Function} cb
 */

const userLog = (userProperties, cb) => {
  let identify = analytics.identify();
  for(let key in userProperties){
    identify.set(key, userProperties[key]);
  }
  analytics.setIdentify(identify, cb);
}

export default {
  eventLog,
  userLog,
  setUserId
};
