import GetData from '@/utils/axios.js'
const { $get, $post } = new GetData()

/**
 * 家长转介绍汇总
 */
const referSummary = function(parentUserId) {
  return $get('/api/finance/parent/refer/summary/' + parentUserId)
}
/**
 * 家长转介绍明细
 */
const referDetail = function(parentUserId, pageNum, pageSize) {
  return $get('/api/finance/parent/refer/detail/' + parentUserId + "/" + pageNum + "/" + pageSize)
}
/**
 * 家长转介绍ace总数
 */
const referSum = function() {
  return $get('/market/coin/amount/2')
}

// 获取短链接
const getShortLink = function(params) {
  return $post('/api/shortlink', params)
}
export default {
  referSummary,
  referDetail,
  referSum,
  getShortLink
}
