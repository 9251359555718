import GetData from '@/utils/axios.js'
const { $get,$post,$put } = new GetData()

// 获取发现页视频列表
const getVideoList = function({ parentUserId, pageNum, pageSize }) {
  return $get(`/discover/video/find/${parentUserId}/${pageNum}/${pageSize}`)
}

// 印尼发现视频列表接口
const getVideoActivityList = function({ parentUserId, pageNum, pageSize }) {
  return $get(`/discover/video/find/activity/${parentUserId}/${pageNum}/${pageSize}`)
}
// 双12活动视频列表接口
const getVideoTwelveList = function({ parentUserId, pageNum, pageSize }) {
  return $get(`/discover/video/double/twelve/group/activity/${parentUserId}/${pageNum}/${pageSize}`)
}

// 双12活动推荐视频
const getRecommendTwelveList = function({ courseVideoId }) {
  return $get(`/discover/video/double/twelve/group/activity/info/${courseVideoId}`)
}

// 印尼推荐视频
const getRecommendActivityList = function({ parentUserId, courseVideoId }) {
  return $get(`/discover/video/recommend/activity/${parentUserId}/${courseVideoId}`)
}

// 获取推荐视频
const getRecommendList = function({ parentUserId, courseVideoId }) {
  return $get(`/discover/video/recommend/${parentUserId}/${courseVideoId}`)
}

// 获取视频详情
const getVideoDetail = function(courseVideoId) {
  return $get(`/discover/video/video/${courseVideoId}`)
}

// 双12活动推荐视频权限
const getVideoTabShow = function({groupId, parentUserId}) {
  return $get(`/discover/video/double/twelve/group/activity/tab/show/${groupId}/${parentUserId}`)
}

//获取视频主题Tab列表
const getDiscoverVideoTabShow = function({parentUserId,topicType}) {
  return $get(`/discover/video/find/topic/${parentUserId}/${topicType}`)
}

//根据主题ID获取视频列表
const getIDVideoList = function({videoTopicId,pageSize,pageNum}) {
  return $get(`/discover/video/find/videos/${videoTopicId}/${pageNum}/${pageSize}`)
}
//推荐视频列表接口
const getIDRecommendVideoList = function({courseVideoId}) {
  return $get(`/discover/video/recommend/videos/${courseVideoId}`)
}
//查询视频详情接口
const getIDDetailVideo = function(courseVideoId) {
  return $get(`/discover/video/video/${courseVideoId}`)
}

//获取视频资源更改名字信息
const getVideoChangeName = function(courseVideoId) {
  return $get(`/discover/video/first/topic/name`)
}
// 获取播放量的接口
const getVideoNum = function(courseVideoId) {
  return $put(`/discover/video/pageviews/${courseVideoId}`)
}

//视频埋点接口
const videoUserData = function(params) {
  return $post(`/discover/video/user/data`,params)
}

export default {
  getVideoList,
  getVideoDetail,
  getVideoTwelveList,
  getRecommendList,
  getVideoActivityList,
  getRecommendActivityList,
  getRecommendTwelveList,
  getVideoTabShow,
  getDiscoverVideoTabShow,
  getIDVideoList,
  getIDRecommendVideoList,
  getIDDetailVideo,
  getVideoChangeName,
  getVideoNum,
  videoUserData
}
