<!-- canvas 示例组件 -->
<template>
  <div>
    <question-list-hw></question-list-hw>
  </div>
</template>
<script>
export default {

  name: 'homework_question',

  data() {
    return {}
  },

  created() {

  },

  mounted() {

  },

  methods: {

  }

}

</script>
<style scoped lang="stylus">
</style>
