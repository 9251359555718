import GetData from '@/utils/axios.js'
const { $get, $post } = new GetData()

/**
 * 用户是否能弹窗
 * userId     {long} 用户id
 */
 const isJoin = function (userId) {
  return $get(`/campaign/refer/activity/check/can/join/${userId}`)
}

/**
 * 用户参加活动是否到期
 * userId     {long} 用户id
 * campaignCode {string} 活动编码
 */
const showActivity = function (userId,campaignCode) {
  return $get(`/campaign/refer/activity/check/time/${campaignCode}/${userId}`)
}

/**
 * 参加活动接口
 *  @param {Object} params 参数对象，包括：
 * username         {string}  用户名
 * phone            {string}  手机号
 * email            {string}  邮箱
 * userParentId     {long}    家长id
 * campaignCode     {string}  活动编码
 * lan              {string}  语言版本
 */
 const joinActivity = function (params) {
  return $post(`/campaign/refer/activity/join`, params)
}

/**
 * 家长端下载是否展示
 * userId     {long} 用户id
 */
 const showDownload = function (userId) {
  return $get(`/campaign/refer/activity/show/download/${userId}`)
}

// 获取下载地址
const downloadAddress = function () {
  return $get(`/campaign/refer/activity/download`)
}
export default {
  isJoin,
  showActivity,
  joinActivity,
  showDownload,
  downloadAddress
}
