// ads 埋点引入
// import { APP_ID } from '@/utils/adsConfig'
import axios from 'axios'
const APP_ID = '9cEPE97R'
const ads = window.__LINGO_ACE_ADS__;
const baseurl = process.env.NODE_ENV === 'development' ? 'https://h-collector-api.lingoace.com/' : 'https://collector-api.lingoace.com/'
export const createAds = () => {
  console.log('createads')
  ads.init(
    axios,
    {
      requestUrl: {
        send: baseurl + 'collector/v1/event/topic',
        auth: baseurl + '/collector/v1/user/aoz'
      },
      authOptions: {
        appId: APP_ID,
        appSecret: 'e6s57chxwrcerbxmtqzou543unjxwid5xh808iv3'
      }
    }
  )
}
// 访问-投放落地页
export const pageView = (params) => {
  console.log('aaaaaaa')
  let time = null
  time = setInterval(() => {
    clearInterval(time)
    if (window.sessionStorage.getItem('PLAT_ADS_TOKEN')) {
      ads.sendAdsEffect({
        data: {
          appId: APP_ID,
          topic: 'LAABL1',
          method: 'async',
          behavior: {
            'eventCode': 'ab_poster_lp_page_view',
            'eventName': '访问-投放落地页',
            'startTime': new Date(),
            'eventCategory': 'access',
            'eventType': 'pageView',
            'properties': mergeParams(params),
            'context': mergeContext()
          }
        }
      })
      clearInterval(time)
    }
  }, 2000)
}


//访问-题库详情页面
export const pageQuestionView = (params) => {
  let time = null
  time = setInterval(() => {
    clearInterval(time)
    if (window.sessionStorage.getItem('PLAT_ADS_TOKEN')) {
      ads.sendAdsEffect({
        data: {
          appId: APP_ID,
          topic: 'LAABL1',
          method: 'async',
          behavior: {
            'eventCode': 'ab_question_lp_page_view',
            'eventName': '访问-题库详情页面',
            'startTime': new Date(),
            'eventCategory': 'access',
            'eventType': 'pageView',
            'properties': mergeQuestionParams(params),
            'context': mergeContext()
          }
        }
      })
      clearInterval(time)
    }
  }, 2000)
}

//点击unity作业结果
export const clickHWAnalysis = (params) => {
  console.log(passParams(params))
  ads.sendAdsEffect({
    data: {
      appId: APP_ID,
      topic: 'LAABL1',
      method: 'async',
      behavior: {
        'eventCode': 'ab_check_unity_result',
        'eventName': '作业提交-点击-查看unity作业结果',
        'startTime': new Date(),
        'eventCategory': 'access',
        'eventType': 'onClick',
        'properties': passParams(params),
        'context': mergeContext()
      }
    }
  })
}

//点击下载pdf作业
export const clickDLpdf = (params) => {
  console.log(passParams(params))
  ads.sendAdsEffect({
    data: {
      appId: APP_ID,
      topic: 'LAABL1',
      method: 'async',
      behavior: {
        'eventCode': 'ab_download_pdf_homework',
        'eventName': '作业提交-点击-下载pdf作业',
        'startTime': new Date(),
        'eventCategory': 'access',
        'eventType': 'onClick',
        'properties': passParams(params),
        'context': mergeContext()
      }
    }
  })
}
//点击历史课程
export const clickHistory = (params) => {
  console.log(passParams(params))
  ads.sendAdsEffect({
    data: {
      appId: APP_ID,
      topic: 'LAABL1',
      method: 'async',
      behavior: {
        'eventCode': 'ab_click_check_history_course',
        'eventName': '作业提交-点击-查看历史课程',
        'startTime': new Date(),
        'eventCategory': 'access',
        'eventType': 'onClick',
        'properties': passParams(params),
        'context': mergeContext()
      }
    }
  })
}
//点击课后巩固
export const clickAssignment = (params) => {
  console.log(passParams(params))
  ads.sendAdsEffect({
    data: {
      appId: APP_ID,
      topic: 'LAABL1',
      method: 'async',
      behavior: {
        'eventCode': 'ab_click_button_assignment',
        'eventName': '作业提交-点击-课后巩固按钮',
        'startTime': new Date(),
        'eventCategory': 'access',
        'eventType': 'onClick',
        'properties': passParams(params),
        'context': mergeContext()
      }
    }
  })
}


export const abTestLp = (params) => {
  ads.sendAdsEffect({
    data: {
      appId: APP_ID,
      topic: 'LABBL3',
      method: 'async',
      behavior: {
        'eventCode': 'ab_refer_ABTEST_view',
        'eventName': 'AB-TEST',
        'startTime': new Date(),
        'eventCategory': 'access',
        'eventType': 'policy',
        'properties': mergeParams(),
        'context': mergeContext(params)
      }
    }
  })
}

const mergeQuestionParams = ({parentId = null, platform = null, courseEditionId = null, courseLevel = null, unitNo = null,lessonNo = null,lessonId = null}) => {
  return {
    'parentId': parentId,
    'platform': platform,
    'courseEditionId': courseEditionId,
    'courseLevel': courseLevel,
    'unitNo': unitNo,
    'lessonNo': lessonNo,
    'lessonId': lessonId
  }
}

const mergeParams = ({poster_id = null, poster_type = null, referrerId = null, id = null,firstReportId=null,channel=null,phone=null,email=null} = {}) => {
  return {
    'posterId': poster_id,
    'posterType': poster_type,
    'referrerId': referrerId,
    'parentUserId': id,
    'firstReportId':firstReportId,
    'channelCode':channel,
    'gaClientId':window.localStorage.getItem('client_id')||'',
    'phone':phone,
    'email':email,

    // 'adsMedia': window.sessionStorage.getItem('gclid') ? 'google' : window.sessionStorage.getItem('fbclid') ? 'fb' : null,
    // 'adsChannel': window.sessionStorage.getItem('channel') || null,
    // 'adsTrackId': window.sessionStorage.getItem('gclid') || window.sessionStorage.getItem('fbclid') || null
  }
}
//

const passParams = ({parentId=null,courseEditionId=null,platform='PC',courseLevel=null,unitNo=null,lessonId=null,lessonNo=null} = {}) =>{
  return {
    'parentId':parentId,
    'courseEditionId':courseEditionId,
    'platform':platform,
    'courseLevel':courseLevel,
    'unitNo':unitNo,
    'lessonId':lessonId,
    'lessonNo':lessonNo,
  }
}
// 增加context 参数
const mergeContext = (params = {}) => {
  return {
    'lan': null, // 语言版本
    'area': null, // 地区
    'appversion': null, // app客户端版本号
    'lp': Object.keys(params).length ? `${params.locid}:${params.policy}` : null, // 命中策略
    'tag': null, // 用户标签
    'ug': null, // 用户组
    'loc': params.loc
  }
}

const feedbackParams = ({parentId = null, lang = null, platform = null}) => {
  return {
    'parentId': parentId,
    'Language': lang,
    'platform': platform
  }
}
// 点击-提交
export const clickSubmit = (params) => {
  ads.sendAdsEffect({
    data: {
      appId: APP_ID,
      topic: 'LABBL5',
      method: 'async',
      behavior: {
        'eventCode': 'bb_poster_lp_refer_submit_success',
        'eventName': '线索留存成功',
        'startTime': new Date(),
        'eventCategory': 'access',
        'eventType': 'onClick',
        'properties': mergeParams(params),
        'context': mergeContext()
      }
    }
  })
}
// 学生端-试听活动弹窗-关闭按钮点击
export const clickCloseActivity = (params) => {
  ads.sendAdsEffect({
    data: {
      appId: APP_ID,
      topic: 'LAABL1',
      method: 'async',
      behavior: {
        'eventCode': 'bb_poster_lp_refer_click_close_activity',
        'eventName': '试听活动弹窗_关闭按钮点击',
        'startTime': new Date(),
        'eventCategory': 'access',
        'eventType': 'onClick',
        'properties': feedbackParams(params),
        'context': mergeContext()
      }
    }
  })
}
//下载家长端弹窗-关闭按钮点击
export const clickCloseParents = (params) => {
  ads.sendAdsEffect({
    data: {
      appId: APP_ID,
      topic: 'LAABL1',
      method: 'async',
      behavior: {
        'eventCode': 'bb_poster_lp_refer_click_close_parents',
        'eventName': '下载家长端弹窗_关闭按钮点击',
        'startTime': new Date(),
        'eventCategory': 'access',
        'eventType': 'onClick',
        'properties': feedbackParams(params),
        'context': mergeContext()
      }
    }
  })
}
//学生端首页-惊喜奖励点击
export const clickDownReward = (params) => {
  ads.sendAdsEffect({
    data: {
      appId: APP_ID,
      topic: 'LAABL1',
      method: 'async',
      behavior: {
        'eventCode': 'bb_poster_lp_refer_click_down_reward',
        'eventName': '学生端首页_惊喜奖励点击',
        'startTime': new Date(),
        'eventCategory': 'access',
        'eventType': 'onClick',
        'properties': feedbackParams(params),
        'context': mergeContext()
      }
    }
  })
}
//学生端首页-家长端下载点击
export const clickDownParents = (params) => {
  ads.sendAdsEffect({
    data: {
      appId: APP_ID,
      topic: 'LAABL1',
      method: 'async',
      behavior: {
        'eventCode': 'bb_poster_lp_refer_click_down_parents',
        'eventName': '学生端首页_家长端下载点击',
        'startTime': new Date(),
        'eventCategory': 'access',
        'eventType': 'onClick',
        'properties': feedbackParams(params),
        'context': mergeContext()
      }
    }
  })
}
//学生端-下载家长端弹窗浏览
export const pageViewParents = (params) => {
  ads.sendAdsEffect({
    data: {
      appId: APP_ID,
      topic: 'LAABL1',
      method: 'async',
      behavior: {
        'eventCode': 'bb_poster_lp_refer_page_view_parents',
        'eventName': '下载家长端弹窗浏览',
        'startTime': new Date(),
        'eventCategory': 'access',
        'eventType': 'pageView',
        'properties': feedbackParams(params),
        'context': mergeContext()
      }
    }
  })
}
//学生端-试听活动弹窗浏览
export const pageViewActivity = (params) => {
  ads.sendAdsEffect({
    data: {
      appId: APP_ID,
      topic: 'LAABL1',
      method: 'async',
      behavior: {
        'eventCode': 'bb_poster_lp_refer_page_view_activity',
        'eventName': '试听活动弹窗浏览',
        'startTime': new Date(),
        'eventCategory': 'access',
        'eventType': 'pageView',
        'properties': feedbackParams(params),
        'context': mergeContext()
      }
    }
  })
}
//试听反馈页面浏览
export const pageViewFeedback = (params) => {
  ads.sendAdsEffect({
    data: {
      appId: APP_ID,
      topic: 'LAABL1',
      method: 'async',
      behavior: {
        'eventCode': 'bb_poster_lp_refer_page_view_feedback',
        'eventName': '试听反馈页面',
        'startTime': new Date(),
        'eventCategory': 'access',
        'eventType': 'pageView',
        'properties': feedbackParams(params),
        'context': mergeContext()
      }
    }
  })
}
//试听反馈页面浏览
export const clickFeedback = (params) => {
  ads.sendAdsEffect({
    data: {
      appId: APP_ID,
      topic: 'LAABL1',
      method: 'async',
      behavior: {
        'eventCode': 'bb_poster_lp_refer_click_feedback',
        'eventName': '点击试听反馈页面_返回按钮',
        'startTime': new Date(),
        'eventCategory': 'access',
        'eventType': 'onClick',
        'properties': feedbackParams(params),
        'context': mergeContext()
      }
    }
  })
}
export default {
  createAds,
  pageView,
  clickHWAnalysis,
  clickDLpdf,
  clickHistory,
  clickAssignment,
  abTestLp,
  pageQuestionView,
  clickSubmit,
  clickCloseActivity,
  clickCloseParents,
  clickDownReward,
  clickDownParents,
  pageViewParents,
  pageViewActivity,
  pageViewFeedback,
  clickFeedback
}
