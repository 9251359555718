<template>
  <div class="top-warpper lg-container">
    <div class="left" @click="contact">
      <img src="@/assets/theme-default/images/index/contactUs.png" alt="">
      <span class="contact">{{$t('Contact Us')}}</span>
    </div>
    <div class="right">
      <ul class="user">
        <li>
          <span class="local" @click="setupLang('en')" :class="{'active':this.$i18n.locale=='en'}" >ENG</span>
          <span class="local" @click="setupLang('zh')" :class="{'active':this.$i18n.locale=='zh'}">中文</span>
        </li>
        <li class="areaTime" id="areaTime" :style="{width:areaTimeWidth}">{{areaTime}}</li>
        <li>
          <Notice :count="1" />
        </li>
        <li>
          <Avatar :avatarUrl="avatarUrl" :username="user.username" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { setup } from "@/locales/index";
import Avatar from "./Avatar";
import Notice from "./Notice";
const jstz = require("@/utils/jstz");
export default {
  components: { Avatar, Notice },
  data() {
    return {
      tel:"(65) 6815 1111",
      avatarUrl: "",
      areaTime: '',
      areaTimeWidth:'auto',
      user: {
        username: this.$store.state.user.user.username
      },
      flag: this.$i18n.locale,
      timer: null
    };
  },
  created() {
    try {
      var timezone = jstz.jstz.determine();
      let timeAddress = timezone.name();
      this.areaTime =
          timeAddress +
          " " +
          this.DateTimeUtils.formartDate(new Date(), "hh:mm:ss");
      this.timer = setInterval(() => {
        this.areaTime =
          timeAddress +
          " " +
          this.DateTimeUtils.formartDate(new Date(), "hh:mm:ss");
      }, 1000);

    } catch (error) {
      if (this.timer) {
        clearInterval(this.timer);
        this.areaTime = "";
      }
    }
    // 处理地区长度过长 数字变化造成的抖动
    this.$nextTick(()=>{
      let areaTimeDom = document.getElementById('areaTime');
      let areaTimeWidth = areaTimeDom.offsetWidth;
       if (areaTimeWidth>200) {
           this.areaTimeWidth = (areaTimeWidth+5)+'px';
       }
    })
  },
  mounted() {

  },
  methods: {
    setupLang(flag) {
      this.flag = flag;
      setup(flag);
      this.$EventBus.$emit("onComputedDescDisplay")
      // 修改设备语言
      this.$api.common.setDeviceLang();
    },
    // 点击联系我们跳转地址
    contact(){
      this.$api.user.getContaUrl({
        "X-LNG":this.$i18n.locale
      }).then(res=>{
        if (res.data.code==200) {
          window.open(res.data.data)
        }
      })
    },
    showSmallClass() {
      this.$router.push({
        name: "smallclass"
      });
    }
  }
};
</script>
