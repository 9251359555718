var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      staticClass: "page-order-list",
      attrs: { "element-loading-spinner": "custom-loading" },
    },
    [
      _vm.listData != null && _vm.listData.length == 0
        ? _c("div", { staticClass: "noData" }, [
            _c("img", { attrs: { src: require("@/images/no-data.png") } }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.$t("No order here")))]),
          ])
        : _c(
            "div",
            _vm._l(_vm.listData, function (i, index) {
              return _c("div", { key: index, staticClass: "single-item" }, [
                _c("div", { staticClass: "line-1" }, [
                  _c("div", { staticClass: "line-1-l" }, [
                    _vm._v(
                      _vm._s(_vm._f("orderCreateDateToFilter")(i.createTime))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "line-1-r" }, [
                    _vm._v(
                      _vm._s(_vm.$t("Order number")) + "：" + _vm._s(i.orderNo)
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "line-2" }, [
                  _c("div", { staticClass: "item item-0" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$i18n.locale == "en"
                            ? i.productNameEn
                            : i.productNameCn
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item item-1" }, [
                    _c("div", { staticClass: "item-1-1" }, [
                      _c("div", [
                        _vm._v(
                          "\n              " +
                            _vm._s(i.rechargeAmount) +
                            " " +
                            _vm._s(_vm.$t("points")) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    i.referrerIncentiveAmount || i.incentiveAmount
                      ? _c("div", { staticClass: "item-1-2" }, [
                          _vm._v(
                            "\n            +" +
                              _vm._s(
                                i.referrerIncentiveAmount + i.incentiveAmount
                              ) +
                              " " +
                              _vm._s(_vm.$t("bonus Points")) +
                              "\n             \n            "
                          ),
                          i.groupId
                            ? _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(_vm.$t("After successful Groupon")) +
                                    ")"
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item item-2" }, [
                    _c("div", { staticClass: "item-2-1" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("dollarInteger")(i.totalPrice)) +
                          "." +
                          _vm._s(_vm._f("dollarDecimal")(i.totalPrice)) +
                          "  " +
                          _vm._s(i.currency)
                      ),
                    ]),
                    _vm._v(" "),
                    i.savePrice != 0
                      ? _c("div", { staticClass: "item-2-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dollarInteger")(
                                  i.originTotalPriceWithTax
                                )
                              ) +
                              "." +
                              _vm._s(
                                _vm._f("dollarDecimal")(
                                  i.originTotalPriceWithTax
                                )
                              ) +
                              " " +
                              _vm._s(i.currency)
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item item-3" }, [
                    _c("div", { staticClass: "item-3-1" }, [
                      i.status == 0 || i.status == 2
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("Pending")))])
                        : _vm._e(),
                      _vm._v(" "),
                      i.status == 1 || i.status == 4
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("Paid")))])
                        : _vm._e(),
                      _vm._v(" "),
                      i.status == 5 || i.status == 6
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("Cancelled")))])
                        : _vm._e(),
                      _vm._v(" "),
                      i.status == 3
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("Refunded")))])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    i.status == 0 || i.status == 2
                      ? _c(
                          "div",
                          { staticClass: "item-3-2" },
                          [
                            _c("OrderCountItem", {
                              attrs: {
                                orderId: i.id,
                                overTime: i.overTime,
                                page: "list",
                              },
                              on: { refreshList: _vm.loadData },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item item-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "view",
                        on: {
                          click: function ($event) {
                            return _vm.jumpToDetail(i.id, i.orderNo)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("View details")))]
                    ),
                    _vm._v(" "),
                    i.status == 0 || i.status == 2
                      ? _c(
                          "div",
                          {
                            staticClass: "pay-btn",
                            on: {
                              click: function ($event) {
                                return _vm.jumpToPayPage(i)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Pay now")))]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ])
            }),
            0
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }