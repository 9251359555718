<!-- ✅ -->
<template>
  <div class="page-order-list" v-loading="pageLoading" element-loading-spinner="custom-loading">
    <div class="noData" v-if="listData != null && listData.length == 0">
      <img src="@/images/no-data.png">
      <span>{{$t('No order here')}}</span>
    </div>
    <div v-else>
      <div class="single-item" v-for="(i, index) in listData" :key="index">
        <div class="line-1">
          <div class="line-1-l">{{i.createTime | orderCreateDateToFilter}}</div>
          <div class="line-1-r">{{$t('Order number')}}：{{i.orderNo}}</div>
        </div>
        <div class="line-2">
          <div class="item item-0">
            {{ $i18n.locale == 'en' ? i.productNameEn : i.productNameCn}}
          </div>
          <div class="item item-1">
            <div class="item-1-1">
              <div>
                {{i.rechargeAmount}} {{$t('points')}}
              </div>
            </div>
            <div class="item-1-2" v-if="i.referrerIncentiveAmount || i.incentiveAmount">
              +{{i.referrerIncentiveAmount + i.incentiveAmount}} {{$t('bonus Points')}}
              &nbsp;
              <span v-if="i.groupId">({{$t('After successful Groupon')}})</span>
            </div>
          </div>
          <div class="item item-2">
            <div class="item-2-1"> {{i.totalPrice | dollarInteger}}.{{i.totalPrice | dollarDecimal}}  {{i.currency}}</div>
            <div class="item-2-2" v-if="i.savePrice != 0"> {{i.originTotalPriceWithTax | dollarInteger}}.{{i.originTotalPriceWithTax | dollarDecimal}} {{i.currency}}</div>
          </div>
          <div class="item item-3">
            <div class="item-3-1">
              <span v-if="i.status == 0 || i.status == 2">{{$t('Pending')}}</span>
              <span v-if="i.status == 1 || i.status == 4">{{$t('Paid')}}</span>
              <span v-if="i.status == 5 || i.status == 6">{{$t('Cancelled')}}</span>
              <span v-if="i.status == 3">{{$t('Refunded')}}</span>
            </div>
            <div class="item-3-2" v-if="i.status == 0 || i.status == 2">
              <OrderCountItem :orderId="i.id" @refreshList="loadData" :overTime="i.overTime" page="list"></OrderCountItem>
            </div>
          </div>
          <div class="item item-4">
            <div class="view" @click="jumpToDetail(i.id, i.orderNo)">{{$t('View details')}}</div>
            <div class="pay-btn" v-if="i.status == 0 || i.status == 2" @click="jumpToPayPage(i)">{{$t('Pay now')}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/index"
import { mapGetters } from 'vuex'
import OrderCountItem from './OrderCountItem'

export default {

  name: 'orderList',

  data() {
    return {
      pageLoading: true,
      listData: null,
    }
  },

  created() {},

  mounted() {
    this.loadData()
  },

  computed: {
    ...mapGetters({
      parentInfo: 'user/getParentInfo'
    }),
  },

  methods: {

    loadData() {
      api.order.getOrderList(1, 500, this.parentInfo.id).then(res => {
        this.pageLoading = false
        if (res.status === 200 && res.data.code === 200) {
          this.listData = res.data.data.list
        } else {
          this.$message(res.data.message)
        }
      })
    },

    jumpToDetail(orderId, orderNo) {
      this.$router.push(`/order/orderDetail?orderId=${orderId}&orderNo=${orderNo}`)
    },

    jumpToPayPage(item) {
      const userId = this.parentInfo.id
      this.$api.order.getUserParentUndoNumber(userId).then(res => {
        if (res && res.data && res.data.code === 200) {
          let index;
          // 未支付订单信息
          if (res.data.data.length==2 && res.data.data.filter((ele,i)=>{
              if (item.orderNo==ele.orderNo) {
                index=i
              }
              return ele.productPackageId == item.productPackageId
            }).length>1 && Math.abs(new Date(res.data.data[0].createTime).getTime() - new Date(res.data.data[1].createTime).getTime()) < 5*60*1000 && index==0) {
            this.$confirm(this.$t('You have an unpaid order for the same product. There might be a network delay to update the payment status.'), this.$t('Tips'), { 
              confirmButtonText: this.$t('confirm'),
              cancelButtonText: this.$t('cancel'),
              type: 'warning'
            }).then(() => {
              if (item.sgClassId || item.groupId || item.packageid) {
                const origin = window.origin
                window.location.replace(`${origin}/billList.html?orderNo=${item.orderNo}`)
              } else {
                this.$router.push(`/pay/billing?orderNo=${item.orderNo}`)
              }
            }).catch(() => {
            })
          }else {
            if (item.sgClassId || item.groupId || item.packageid) {
              const origin = window.origin
              window.location.replace(`${origin}/billList.html?orderNo=${item.orderNo}`)
            } else {
              this.$router.push(`/pay/billing?orderNo=${item.orderNo}`)
            }
          }
        } else {
          this.$message({
            message: res.data.message,
          })
        }
      })
     
    },
  },

  components: {
    OrderCountItem
  }

}

</script>
<style scoped lang="stylus">
.page-order-list {
  height: 100%;

  .single-item {
    height: 126px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 4px rgba(182, 182, 182, 0.08);
    border-radius: 14px;
    margin-bottom: 20px;
    padding: 0 30px;

    .line-1 {
      display: flex;
      justify-content: space-between;
      line-height: 42px;
      border-bottom: 2px solid #DEDEDE;
      color: #999;

      .line-1-l {
        color: #666;
      }
    }

    .line-2 {
      display: flex;

      .item {
        width: 25%;
        padding-top: 18px;

        &.item-1 {
          .item-1-1 {
            font-size: 18px;
            color: #34364D;
            width: 277px;
            margin-left :70px;


            img {
              width: 60px;
              vertical-align: top;
              margin-top: -3px;
            }
          }

          .item-1-2 {
            font-size: 14px;
            color: #666;
            margin-top: 6px;
            margin-left: 69px;

          }
        }

        &.item-2 {
          text-align: center;
          width : 268px;

          .item-2-1 {
            color: #34364D;
            margin-bottom: 10px;
            font-size: 18px;
          }

          .item-2-2 {
            color: #999;
            font-size: 14px;
            text-decoration: line-through;
          }
        }

        &.item-3 {
          text-align: center;

          .item-3-1 {
            color: #34364D;
            font-size: 18px;
            margin-bottom: 10px;
          }

          .item-3-2 {
            color: #999;
            font-size: 14px;
            width: 159px;

          }
        }

        &.item-4 {
          display: flex;
          justify-content: flex-end;

          /*align-items: right;*/
          .view {
            font-size: 14px;
            color: #7580E5;
            margin-right: 10px;
            line-height: 30px;
            cursor: pointer;
            width: 75px;

          }

          .pay-btn {
            height: 40px;
            width: 100px;
            background: #FFA13C;
            border-radius: 44px;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.noData {
  text-align: center;
  color: #666;
  img {
    width: 200px;
    height: 200px;
    display: block;
    margin: 50px auto 30px;
  }
}

</style>
