<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="40%"
    class="dialog-win">
    <span class="desc-text">
      <template v-if="userAgentType">
        {{$t('Your current browser is')}}&nbsp;{{userAgentType}}，
      </template>
      <template v-else>
        {{$t('Your current browser is not a')}}&nbsp;Chrome&nbsp;{{$t('browser')}}，
      </template>
      {{$t('Please switch to the')}}&nbsp;Chrome&nbsp;{{$t('browser')}}
    </span>
    <span slot="footer">
      <el-button type="primary" @click="dialogVisible = false">{{$t('Confirm')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { isMobile,getPlatformInfo } from "@lingoace/environment-lingoace";
export default {
  data (){
    return {
      isMobile: isMobile(),
      dialogVisible: false,
      userAgentType: ''
    }
  },
  mounted () {
    this.userAgentType = getPlatformInfo().browser;
    if (this.userAgentType === 'Chrome') {
      return;
    }
    this.dialogVisible = true && !this.isMobile
  },
}
</script>

<style lang="stylus">
.dialog-win .el-dialog__footer {
  text-align : right;
}
.desc-text {
  word-break: keep-all;
  line-height: 24px;
}
</style>