import GetData from '@/utils/axios.js'
const { $get,$post } = new GetData()

// 获取我的课程
const myProgram = (params) => {
  return $post('/api/trading/center/program/my/program', params)
}

// 获取其他课程的spu
const exploreProgram = (params) => {
  return $post('/api/trading/center/program/explore/program', params)
}

//获取币种列表
const currencyList = () => {
    return $get('/api/trading/center/common/exchange/rate/list')
  }


export default {
  myProgram,
  exploreProgram,
  currencyList
}
