import GetData from '@/utils/axios.js'
const { $get, $post } = new GetData();

const getTutorFreeTime = function(coursePackageId, userId, classTypeId, startTime, endTime) {
  return $get(`/api/schedule/tutor/schedule/freeTimeV2/${coursePackageId}/${userId}/${classTypeId}/${startTime}/${endTime}`)
}

const subscribeClass = function(info,pageName="other") {
  return $post(`/api/schedule/student/subscribe`, info, {
    name:`${pageName}约课接口request`
  })
}

const cancelBooking = function(info,pageName="other") {
  return $post(`/api/schedule/student/cancel`, info,{
    name:`${pageName}取消课程接口request`
  })
}

/**
 * 获取学生进度
 * @param coursePackageId {Number} 课包标识
 */
const getLearningInfo = function(params){
  return $get(`/api/schedule/student/learning/plan`, params)
}

export default {
  getTutorFreeTime,
  subscribeClass,
  cancelBooking,
  getLearningInfo
}
