import axios from 'axios';
import md5 from 'md5';
const measurement_id = `G-WEE1MQV658`;
const api_secret = `Bya5VZ-XQyihkhYrQ6NoHQ`;


const measurementId = `G-FRS01HXNDL`;
const apiSecret = `XZBWbQ9PQpidWb7ulLHVxA`;

let client_id = window.localStorage.getItem('client_id')


//取消课程弹窗
export const classCancel_click = (params) => {
  let getparams = ({
    eventName = '',
    parentId = '',
    studentId = '',
    teacherId = '',
    pageRoute = '',
    virtualclassId=''
  }) => {
    return {
      'eventType': "click",
      'eventName': eventName,
      'eventTerminal': 6,
      'time': new Date(),
      'parentId': parentId,
      'studentId': studentId,
      'teacherId': teacherId,
      'pageRoute': pageRoute,
      'virtualclassId':virtualclassId
    }
  }
  fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${measurementId}&api_secret=${apiSecret}`, {
    method: "POST",
    body: JSON.stringify({
      client_id: window.localStorage.getItem('client_id'),
      events: [{
        name: params.name,
        params: getparams(params),
      }],
    })
  });
}

// 上报（默认是点击事件）
export const log = (params = {}) => {
  fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${measurementId}&api_secret=${apiSecret}`, {
    method: "POST",
    body: JSON.stringify({
      client_id: window.localStorage.getItem('client_id'),
      events: [{
        name: params.name,
        params: {
          'eventType': "click",
          'eventTerminal': 6,
          'time': new Date(),
           ...params,
        },
      }],
    })
  })
}
//历史课程-本课小结-提交反馈按钮点击
export const submitFeedback_click = (params) => {
  let getuserinfo = ({
    eventName = '',
    parentId = '',
    studentId = ''
  }) => {
    return {
      'eventType': "click",
      'eventName': eventName,
      'eventTerminal': 6,
      'time': new Date(),
      'parentId': parentId,
      'studentId': studentId
    }
  }
  fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${measurementId}&api_secret=${apiSecret}`, {
    method: "POST",
    body: JSON.stringify({
      client_id: window.localStorage.getItem('client_id'),
      events: [{
        name: params.name,
        params: getuserinfo(params),
      }],
    })
  });
}  
//点击事件上报
export const appLgGtagJs = (params) => {
  fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${measurementId}&api_secret=${apiSecret}`, {
    method: "POST",
    body: JSON.stringify({
      client_id: window.localStorage.getItem('client_id'),
      events: [{
        name: params.name,
        params: deliverParams(params),
      }],
    })
  });
}

//作业点击事件上报
export const webHome_click = (params) => {
  fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${measurementId}&api_secret=${apiSecret}`, {
    method: "POST",
    body: JSON.stringify({
      client_id: window.localStorage.getItem('client_id'),
      events: [{
        name: params.name,
        params: HomeParamsClick(params),
      }],
    })
  });
}

//曝光事件上报
export const appGtagJs_show = (params) => {
  fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${measurementId}&api_secret=${apiSecret}`, {
    method: "POST",
    body: JSON.stringify({
      client_id: client_id,
      events: [{
        name: params.name,
        params: passParams(params),
      }],
    })
  });
}
//作业曝光事件上报
export const webHomeShow = (params) => {
  fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${measurementId}&api_secret=${apiSecret}`, {
    method: "POST",
    body: JSON.stringify({
      client_id: window.localStorage.getItem('client_id'),
      events: [{
        name: params.name,
        params: HomeParamsShow(params),
      }],
    })
  });
}


//数据看板参数for show
const passParams = ({
  eventName = '',
  userId = '',
  countryNameEN = '',
  countryNameCN = ''
}) => {
  return {
    'eventType': "show",
    'eventName': eventName,
    'eventTerminal': 6,
    'time': new Date(),
    'userId': userId === '' ? '' : md5(userId),
    'countryNameEN': countryNameEN,
    'countryNameCN': countryNameCN,
  }
}


//数据看板参数for click
const deliverParams = ({
  eventName = '',
  userId = '',
  countryNameEN = '',
  countryNameCN = '',
  referType = '',
  lessonId = '',
  courseEditionId = '',
  courseLevel = '',
  referPath = ''
}) => {
  return {
    'eventType': "click",
    'eventName': eventName,
    'eventTerminal': 6,
    'time': new Date(),
    'userId': userId === '' ? '' : md5(userId),
    'countryNameEN': countryNameEN,
    'countryNameCN': countryNameCN,
    'referType': referType,
    'lessonId': lessonId,
    'courseEditionId': courseEditionId,
    'courseLevel': courseLevel,
    'referPath': referPath
  }
}

//主页数据参数for click
const HomeParamsClick = ({
  eventName = '',
  parentId = '',
  studentId = '',
  teacherId = '',
  courseEditionId = '',
  courseLevel = '',
  unitNo = '',
  lessonId = '',
  lessonNo = ''
}) => {
  return {
    'eventType': "click",
    'eventName': eventName,
    'eventTerminal': 6,
    'time': new Date(),
    'parentId': parentId,
    'studentId': studentId,
    'teacherId': teacherId,
    'courseEditionId': courseEditionId,
    'courseLevel': courseLevel,
    'unitNo': unitNo,
    'lessonId': lessonId,
    'lessonNo': lessonNo
  }
}

//主页数据参数for show
const HomeParamsShow = ({
  eventName = '',
  parentId = '',
  studentId = '',
  teacherId = '',
  courseEditionId = '',
  courseLevel = '',
  unitNo = '',
  lessonId = '',
  lessonNo = ''
}) => {
  return {
    'eventType': "show",
    'eventName': eventName,
    'eventTerminal': 6,
    'time': new Date(),
    'parentId': parentId,
    'studentId': studentId,
    'teacherId': teacherId,
    'courseEditionId': courseEditionId,
    'courseLevel': courseLevel,
    'unitNo': unitNo,
    'lessonId': lessonId,
    'lessonNo': lessonNo
  }
}

export default {
  // axiosEvent,
  appLgGtagJs,
  appGtagJs_show,
  webHome_click,
  webHomeShow,
  log,
}
