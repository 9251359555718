import Vue from 'vue'
import axios from 'axios'
import { loadFromLocal, getLang, clearLocalExcept } from '@/common/js/store'
import datetime from '@/utils/datetime_utils';
import { errorCodes } from '@/utils/error_code';
import { findRedirect } from '@/common/js/redirectList';
import getUrlParams from '../utils/getUrlParams';
import reporter from '@/utils/reporter'

const jstz = require('@/utils/jstz')

// 处理请求头数据和处理结果
class HandleParamAndResult {
  constructor() {
    if ( process.env.NODE_ENV === 'development'|| process.env.NODE_ENV === 'sim') {
      var HOST = process.env.VUE_APP_BASE_API
      this.api = HOST
    } else {
      this.api = window.location.protocol + '//' + window.location.host
    }
  }

  // 添加请求头
  _addHeaders() {
    // 自定义headers
    let timezone = jstz.jstz.determine();
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-LNG': getLang(),
      'X-TZ': timezone.name() + ' ' + datetime.getTimeZone(),
    }
    // console.log(getLang(), timezone.name() + ' ' + datetime.getTimeZone())
    const token = loadFromLocal('login_token', 'jwtToken', null);
    if (token) { // 是否有token
      headers.Authorization = `Bearer ${token}`
    }
    return headers
  }

  // 处理请求
  dealAxios(url, options) {
    const _this = this
    let urlparams = getUrlParams();
    options.headers = url=='/discover/video/user/data'?{...this._addHeaders(),'client':'pc'}:this._addHeaders();
    // 判断登录没有并且接口不是注册和登录
    const local = loadFromLocal('login_info', 'user', null);
    // console.log('####登录####', url, local)
    if (
        url != '/api/user/login'
        && url != '/api/user/register'
        && url !== "/api/user/potential/country"
        && url !== "/api/common/country/list"
        && url !== "/api/common/country/part/list"
        && url !== "/api/user/logintips"
        && url !== "/api/common/v2/policy"
        && url !== "/api/course/edition/list"
        && url !== "/api/user/validatePhone"
        && url !== "/webapi/surveyResult"
        && url !== "/api/common/campaign/channel"
        && !url.includes("/api/user/logining/user/info")
        && !url.includes( "/facade/unitReport/splicing/short/url/v3/")
      ) {
      if (!local) {
        let redirect = findRedirect();
        redirect = encodeURIComponent(redirect + window.location.search);
        window.location.href = '/login.html?redirect=' + redirect + (urlparams.languageTag?`&languageTag=${urlparams.languageTag}`:'');
        return Promise.resolve({
          data: {
            data: null,
            code: -999,
            message: 'Not logged in'
          }
        });
      }
    }

    if (url.indexOf('/campaign/') == 0) {
      _this.api =  process.env.VUE_APP_CAMPAIGN_HOST
    }
    if (url.indexOf('/discover/') == 0) {
      _this.api =  process.env.VUE_APP_DISCOVER_HOST
    }
    if (url.indexOf('/webapi/') == 0) {
      _this.api =  process.env.VUE_APP_REFERS_HOST
    }
    if (url.includes('/data-transfer/')) {
      _this.api =  process.env.VUE_APP_DATATRANSFER_HOST
    }
    if (url.indexOf('/homework/') == 0) {
      _this.api =  process.env.VUE_APP_HOMEWORK_HOST
    }
    if (url.indexOf('/program/') === 0) {
      _this.api =  process.env.VUE_APP_PROGRAM_HOST
    }
    if (url.indexOf('/preview/') == 0) {
      _this.api =  process.env.VUE_APP_UNITY_HOST
    }
    if(url.includes('/facade/')){
      _this.api =  process.env.VUE_APP_FACADE_HOST
    }
    // do something before request
    return new Promise((resolve, reject) => {
      axios(_this.api + url, options).then(res => {
      // axios(url, options).then(res => {
        if (res.data.code == 401) {
          // 关闭所有消息实例
          Vue.prototype.$message.closeAll();
          // token过期message 且只有一条
          // !document.querySelectorAll('._tokenInvalidMsg').length &&
          Vue.prototype.$message({
            message: errorCodes[401][window.localStorage.getItem("localeLanguage") == 'zh'?0:1],
            duration: 3000,
            customClass:'_tokenInvalidMsg'
          })
          // 跳转登录页
          setTimeout(() => {
            let redirect = findRedirect();
            redirect = encodeURIComponent(redirect + window.location.search);
            window.location.href = '/login.html?redirect=' + redirect + (urlparams.languageTag?`&languageTag=${urlparams.languageTag}`:'');
            clearLocalExcept();
          }, 500);

        } else if (res.data.code == 200) {
          // 请求成功
        } else if (res.data.code == 500) {
          // 参数或者校验失败
          if (window.localStorage.getItem("localeLanguage") == 'zh') {
            Vue.prototype.$message({
              message: errorCodes[res.data.code][0],
              duration: 3000
            })
          } else {
            Vue.prototype.$message({
              message: errorCodes[res.data.code][1],
              duration: 3000
            });
          }
        } else if ([406,407,30515,91107,91101,70002,70011,30519,30520,30521].includes(+res.data.code)) {
          resolve(res)
          return;
        } else {
          if (url.includes('/api/schedule/student/subscribe')&&res.data.code==30004) {
            resolve(res)
            return
          }
          // 如果后端返回的错误码本地不存在
          if (!errorCodes[res.data.code]) {
            Vue.prototype.$message({
              message: res.data.message,
              duration: 3000
            })
          } else {
            if (window.localStorage.getItem("localeLanguage") == 'zh') {
              Vue.prototype.$message({
                message: errorCodes[res.data.code][0],
                duration: 3000
              });
            } else {
              Vue.prototype.$message({
                message: errorCodes[res.data.code][1],
                duration: 3000
              });
            }
          }
        }
        resolve(res)
      }).catch(error => {
        // console.log(_this.api + url)
        // console.log('error');
        reject(error.message);
        console.log(error.message);
      })
    })
  }
}
/**
 * 封装请求方法
 * @param { Number | String } 平台传递的版本号
 */
class RequestMethod {
  constructor() {
    this.$get = this.$get.bind(this)
    this.$post = this.$post.bind(this)
    this.$put = this.$put.bind(this)
    this.$delete = this.$delete.bind(this)
    this.$upload = this.$upload.bind(this)
    this._method = this._method.bind(this)
  }

  /**
   * 整合请求方法
   * @param { String } url 接口路由
   * @param { Object } axios配置 请求参数
   */
  _method(url, options, reportLogan) {
    // 特定接口上报logan
    if (reportLogan) {
      try {
        reporter.report({
          name:reportLogan.name,
          requestUrl:url,
          requestOptions:options
        })
      } catch (error) {
        console.log(error);
      }
    }
    return new HandleParamAndResult().dealAxios(url, options)
  }

  /**
   * get方法
   * @param { String } url 接口路由
   * @param { Object } data 请求参数
   */
  $get(url, data ,reportLogan) {
    return this._method(url, {
      params: data,
      method: 'get'
    },reportLogan)
  }

  /**
   * post方法
   * @param { String } url 接口路由
   * @param { Object } data 请求参数
   */
  $post(url, data, reportLogan) {
    return this._method(url, {
      data: data,
      method: 'post',
      type: 'json'
    },reportLogan)
  }

  /**
   * put方法
   * @param { String } url 接口路由
   * @param { Object } data 请求参数
   */
  $put(url, data, reportLogan) {
    return this._method(url, {
      data: data,
      method: 'put',
      type: 'json'
    },reportLogan)
  }

  /**
   * delete方法
   * @param { String } url 接口路由
   * @param { Object } data 请求参数
   */
  $delete(url, data, reportLogan) {
    return this._method(url, {
      params: data,
      method: 'delete'
    },reportLogan)
  }

  /**
   * 上传方法
   * @param { String } url 接口路由
   * @param { Object } data 请求参数
   */
  $upload(url, data, reportLogan) {
    return this._method(url, {
      body: data,
      method: 'post',
      type: 'formData'
    },reportLogan)
  }
}

export default RequestMethod
