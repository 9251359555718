var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.orderDetail
    ? _c(
        "div",
        { staticClass: "page-order-detail" },
        [
          _vm.adviserOrXgInfo
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.xgDialogVisible,
                    center: "",
                    "show-close": false,
                    width: "550px",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.xgDialogVisible = $event
                    },
                  },
                },
                [
                  _c("div", { staticClass: "main-title" }, [
                    _vm._v(_vm._s(_vm.adviserOrXgTitile)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "main-title" }, [
                    _vm._v(_vm._s(_vm.adviserOrXgTitilexiao)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dialog-text" }, [
                    _vm.adviserOrXgInfo.wechatQrcode
                      ? _c("div", { staticClass: "img" }, [
                          _c("img", {
                            attrs: { src: _vm.adviserOrXgInfo.wechatQrcode },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.adviserOrXgInfo.wechat
                      ? _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("Wechat")) +
                              "：" +
                              _vm._s(_vm.adviserOrXgInfo.wechat)
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.adviserOrXgInfo.phone
                      ? _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("Mobile number")) +
                              "：" +
                              _vm._s(_vm.adviserOrXgInfo.phone)
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.adviserOrXgInfo.whatsapp
                      ? _c("div", [
                          _vm._v(
                            "WhatApp：" + _vm._s(_vm.adviserOrXgInfo.whatsapp)
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.xgDialogVisible = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Comfirm")))]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("Call the number below to get more services"),
                visible: _vm.tipDialogVisible,
                center: "",
                "show-close": false,
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.tipDialogVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "dialog-text" }, [
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("Beijing office")) + "：+86 010 62538271"
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("Singapore office")) + "：+65 68151111"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "footer-btn",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.tipDialogVisible = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("Comfirm")))]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "title",
              class: _vm.$i18n.locale == "en" ? "" : "zh-font",
            },
            [_vm._v(_vm._s(_vm.$t("Order details")))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "page-order-detail-box" }, [
            _c(
              "div",
              {
                staticClass: "line-1",
                class: _vm.$i18n.locale == "en" ? "" : "zh-font",
              },
              [
                _vm._v(_vm._s(_vm.$t("Status")) + ":\n      "),
                _vm.orderDetail.status == 0 || _vm.orderDetail.status == 2
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("Pending")))])
                  : _vm._e(),
                _vm._v(" "),
                _vm.orderDetail.status == 1 || _vm.orderDetail.status == 4
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("Paid")))])
                  : _vm._e(),
                _vm._v(" "),
                _vm.orderDetail.status == 3
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("Refunded")))])
                  : _vm._e(),
                _vm._v(" "),
                _vm.orderDetail.status == 5 || _vm.orderDetail.status == 6
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("Cancelled")))])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _vm.orderDetail.status == 0 || _vm.orderDetail.status == 2
              ? _c(
                  "div",
                  { staticClass: "line-2" },
                  [
                    _c("OrderCountItem", {
                      attrs: {
                        orderId: _vm.orderDetail.id,
                        overTime: _vm.orderDetail.overTime,
                        page: "detail",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "line-student" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("Student")) +
                  ": " +
                  _vm._s(_vm.orderDetail.studentUserName) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _vm.orderDetail.status != 3
              ? _c("div", { staticClass: "line-3" }, [
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.$t("Top-uped product")) +
                        "：" +
                        _vm._s(
                          _vm.$i18n.locale == "en"
                            ? _vm.orderDetail.productNameEn
                            : _vm.orderDetail.productNameCn
                        ) +
                        " "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.$t("Top-uped points")) +
                        "：" +
                        _vm._s(_vm.orderDetail.rechargeAmount) +
                        " " +
                        _vm._s(_vm.$t("points"))
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.orderDetail.incentiveAmount +
                  _vm.orderDetail.referrerIncentiveAmount
                    ? _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("Awarded points")) +
                            "：" +
                            _vm._s(
                              _vm.orderDetail.incentiveAmount +
                                _vm.orderDetail.referrerIncentiveAmount
                            ) +
                            _vm._s(_vm.$t("Points"))
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.orderDetail.status == 0 ||
                    _vm.orderDetail.status == 1 ||
                    _vm.orderDetail.status == 2 ||
                    _vm.orderDetail.status == 4) &&
                  _vm.orderDetail.originTotalPrice
                    ? _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("Original price")) +
                            "：" +
                            _vm._s(
                              _vm._f("dollarInteger")(
                                _vm.orderDetail.originTotalPrice
                              )
                            ) +
                            "." +
                            _vm._s(
                              _vm._f("dollarDecimal")(
                                _vm.orderDetail.originTotalPrice
                              )
                            ) +
                            " " +
                            _vm._s(_vm.orderDetail.currency)
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.orderDetail.status == 0 ||
                    _vm.orderDetail.status == 1 ||
                    _vm.orderDetail.status == 2 ||
                    _vm.orderDetail.status == 4) &&
                  _vm.orderDetail.originTotalPrice
                    ? _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("Discounted price")) +
                            "：" +
                            _vm._s(
                              _vm._f("dollarInteger")(_vm.orderDetail.savePrice)
                            ) +
                            "." +
                            _vm._s(
                              _vm._f("dollarDecimal")(_vm.orderDetail.savePrice)
                            ) +
                            " " +
                            _vm._s(_vm.orderDetail.currency) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.orderDetail.status == 0 ||
                    _vm.orderDetail.status == 1 ||
                    _vm.orderDetail.status == 2 ||
                    _vm.orderDetail.status == 4) &&
                  _vm.orderDetail.taxFee
                    ? _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("GST")) +
                            "：" +
                            _vm._s(
                              _vm._f("dollarInteger")(_vm.orderDetail.taxFee)
                            ) +
                            "." +
                            _vm._s(
                              _vm._f("dollarDecimal")(_vm.orderDetail.taxFee)
                            ) +
                            " " +
                            _vm._s(_vm.orderDetail.currency)
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.orderDetail.status == 1 || _vm.orderDetail.status == 4
                    ? _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("Paid price")) +
                            "：" +
                            _vm._s(
                              _vm._f("dollarInteger")(
                                _vm.orderDetail.totalPrice
                              )
                            ) +
                            "." +
                            _vm._s(
                              _vm._f("dollarDecimal")(
                                _vm.orderDetail.totalPrice
                              )
                            ) +
                            " " +
                            _vm._s(_vm.orderDetail.currency) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.orderDetail.status == 0 ||
                    _vm.orderDetail.status == 1 ||
                    _vm.orderDetail.status == 2 ||
                    _vm.orderDetail.status == 4) &&
                  _vm.orderDetail.code
                    ? _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("Coupon code")) +
                            "：" +
                            _vm._s(_vm.orderDetail.code)
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.$t("Order number")) +
                        "：" +
                        _vm._s(_vm.orderDetail.orderNo)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.$t("Order time")) +
                        "：" +
                        _vm._s(
                          _vm.DateTimeUtils.dateClockTime(
                            _vm.orderDetail.createTime
                          )
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.orderDetail.status == 5 || _vm.orderDetail.status == 6
                    ? _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("Cancellation time")) +
                            "：" +
                            _vm._s(
                              _vm.DateTimeUtils.dateClockTime(
                                _vm.orderDetail.updateTime
                              )
                            )
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.orderDetail.status == 1 || _vm.orderDetail.status == 4
                    ? _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("Payment time")) +
                            "：" +
                            _vm._s(
                              _vm.DateTimeUtils.dateClockTime(
                                _vm.orderDetail.updateTime
                              )
                            )
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.orderDetail.status == 1 || _vm.orderDetail.status == 4
                    ? _c("div", [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("Payment Mode")) +
                            "：\n        "
                        ),
                        _vm.orderDetail.payType == "card"
                          ? _c("span", [_vm._v(_vm._s(_vm.$t("Card payment")))])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.orderDetail.payType == "alipay"
                          ? _c("span", [_vm._v(_vm._s(_vm.$t("Alipay")))])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.orderDetail.payType == "wechat"
                          ? _c("span", [_vm._v(_vm._s(_vm.$t("Wechat")))])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.orderDetail.payType == "atome installment"
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.orderDetail.payType)),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.orderDetail.payType == "Affirm"
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.orderDetail.payType)),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.orderDetail.payType == "klarna"
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.orderDetail.payType)),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.orderDetail.status == 1 || _vm.orderDetail.status == 4
                    ? _c("div", [
                        _vm.orderDetail.receiptStatus != 9 ||
                        (_vm.orderDetail.receiptStatus == 9 &&
                          _vm.orderDetail.receiptUrl)
                          ? _c("div", { staticClass: "receipt" }, [
                              _vm.openApplicationReceipt
                                ? _c(
                                    "span",
                                    {
                                      on: { click: _vm.openApplicationReceipt },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("View reciept")))]
                                  )
                                : _c("span", { staticClass: "tips" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Only show receipts after 2020-01-01"
                                        )
                                      )
                                    ),
                                  ]),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.orderDetail.status == 0
                    ? _c("div", { staticClass: "action-0" }, [
                        _c(
                          "div",
                          {
                            staticClass: "cancel",
                            on: { click: _vm.cancelOrder },
                          },
                          [_vm._v(_vm._s(_vm.$t("Cancel Order")))]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "pay-box" }, [
                          _c("div", { staticClass: "amount" }, [
                            _vm._v(_vm._s(_vm.$t("Paid price")) + ":"),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#FFA13C",
                                  "font-size": "20px",
                                },
                              },
                              [
                                _vm._v(
                                  "  " +
                                    _vm._s(
                                      _vm._f("dollarInteger")(
                                        _vm.orderDetail.totalPrice
                                      )
                                    ) +
                                    "." +
                                    _vm._s(
                                      _vm._f("dollarDecimal")(
                                        _vm.orderDetail.totalPrice
                                      )
                                    ) +
                                    " " +
                                    _vm._s(_vm.orderDetail.currency)
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "pay-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpToPayPage(_vm.orderDetail)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("Pay now")))]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.orderDetail.status == 1 || _vm.orderDetail.status == 4
                    ? _c("div", { staticClass: "action-1" }, [
                        _c(
                          "div",
                          {
                            staticClass: "aftermarket",
                            on: { click: _vm.getAdviserOrXgInfo },
                          },
                          [_vm._v(_vm._s(_vm.$t("Inquiry")))]
                        ),
                      ])
                    : _vm._e(),
                ])
              : _c("div", [
                  _c("div", { staticClass: "line-3" }, [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("Top-uped product")) +
                          "：" +
                          _vm._s(
                            _vm.$i18n.locale == "en"
                              ? _vm.orderDetail.productNameEn
                              : _vm.orderDetail.productNameCn
                          )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("Amount Refunded")) +
                          "：" +
                          _vm._s(_vm.orderDetail.refundMoney || "无")
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("Refund time")) +
                          "：" +
                          _vm._s(
                            _vm.DateTimeUtils.dateClockTime(
                              _vm.orderDetail.refundTime
                            )
                          )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("Point used")) +
                          "：" +
                          _vm._s(_vm.orderDetail.consumeLessonNum || "无")
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("Point retrieved")) +
                          "：" +
                          _vm._s(_vm.orderDetail.collectionLessonNum || "无")
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "line-3-inner-box" }, [
                      _c("div", { staticClass: "line-3" }, [
                        _c("div", { staticStyle: { color: "#333" } }, [
                          _vm._v(_vm._s(_vm.$t("Order details"))),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("Top-uped points")) +
                              "：" +
                              _vm._s(_vm.orderDetail.rechargeAmount) +
                              _vm._s(_vm.$t("Points"))
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.orderDetail.incentiveAmount +
                        _vm.orderDetail.referrerIncentiveAmount
                          ? _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("Awarded points")) +
                                  "：" +
                                  _vm._s(
                                    _vm.orderDetail.incentiveAmount +
                                      _vm.orderDetail.referrerIncentiveAmount
                                  ) +
                                  _vm._s(_vm.$t("Points"))
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(_vm._s(_vm.$t("Points type")) + "："),
                          _vm.orderDetail.sgClassId
                            ? _c("span", [_vm._v(_vm._s(_vm.$t("sgPoints")))])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.$t("Normal Points"))),
                              ]),
                        ]),
                        _vm._v(" "),
                        _vm.orderDetail.originTotalPrice
                          ? _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("Original price")) +
                                  "：" +
                                  _vm._s(_vm.orderDetail.currency) +
                                  " " +
                                  _vm._s(
                                    _vm._f("dollarInteger")(
                                      _vm.orderDetail.originTotalPrice
                                    )
                                  ) +
                                  "." +
                                  _vm._s(
                                    _vm._f("dollarDecimal")(
                                      _vm.orderDetail.originTotalPrice
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.orderDetail.originTotalPrice
                          ? _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("Discounted price")) +
                                  "：" +
                                  _vm._s(_vm.orderDetail.currency) +
                                  " " +
                                  _vm._s(
                                    _vm._f("dollarInteger")(
                                      _vm.orderDetail.savePrice
                                    )
                                  ) +
                                  "." +
                                  _vm._s(
                                    _vm._f("dollarDecimal")(
                                      _vm.orderDetail.savePrice
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.orderDetail.taxFee
                          ? _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("GST")) +
                                  "：" +
                                  _vm._s(_vm.orderDetail.currency) +
                                  " " +
                                  _vm._s(
                                    _vm._f("dollarInteger")(
                                      _vm.orderDetail.taxFee
                                    )
                                  ) +
                                  "." +
                                  _vm._s(
                                    _vm._f("dollarDecimal")(
                                      _vm.orderDetail.taxFee
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("Paid price")) +
                              "：" +
                              _vm._s(_vm.orderDetail.currency) +
                              " " +
                              _vm._s(
                                _vm._f("dollarInteger")(
                                  _vm.orderDetail.totalPrice
                                )
                              ) +
                              "." +
                              _vm._s(
                                _vm._f("dollarDecimal")(
                                  _vm.orderDetail.totalPrice
                                )
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("Order number")) +
                              "：" +
                              _vm._s(_vm.orderDetail.orderNo)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("Order time")) +
                              "：" +
                              _vm._s(
                                _vm.DateTimeUtils.dateClockTime(
                                  _vm.orderDetail.createTime
                                )
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("Payment time")) +
                              "：" +
                              _vm._s(
                                _vm.DateTimeUtils.dateClockTime(
                                  _vm.orderDetail.updateTime
                                )
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(_vm._s(_vm.$t("Payment Mode")) + "："),
                          _vm.orderDetail.payType == "card"
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("Card payment"))),
                              ])
                            : _vm._e(),
                          _vm.orderDetail.payType == "alipay"
                            ? _c("span", [_vm._v(_vm._s(_vm.$t("Alipay")))])
                            : _vm._e(),
                          _vm.orderDetail.payType == "wechat"
                            ? _c("span", [_vm._v(_vm._s(_vm.$t("Wechat")))])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }