import GetData from '@/utils/axios.js'

const { $get, $post } = new GetData()

const getHomeworkList = function(params) {
  return $get(`/api/homework/query/${params}`)
}

/**
 * 检测此lesson是否有试卷
 * lessonId {number} 课堂Id
 */
const checkHomeworkExist = function(virtualClassId, lessonId, classTypeId, studentId) {
  return $get(`/api/homework/checkHomeworkExist/${virtualClassId}/${lessonId}/${classTypeId}/${studentId}`)
}

/**
 * 生成当前试卷
 * virtualClassId {number} 小班课Id
 * lessonId {number} 版本Id
 * studentId {number} 学生Id
 * tutorUserId {number} 家长Id
 */
const createHomeworkDetail = function(lessonId, studentId, tutorUserId) {
  return $get(`/api/homework/buildStudentHomework/${lessonId}/${studentId}/${tutorUserId}`)
}

/**
 * 获取当前试卷
 * lessonId {number} 版本Id
 * studentId {number} 学生Id
 */
const getHomeworkDetail = function(lessonId, studentId) {
  return $get(`/api/homework/queryStudentHomework/${lessonId}/${studentId}`)
}

/**
 * 教师评价
 * virtualClassId {number} 课堂Id
 * studentId {number} 学生Id
 */
const getHomeworkTeacherFeedback = function(virtualClassId, classTypeId, studentId) {
  return $get(`/api/homework/queryVirtualClassHomeworkResult/${virtualClassId}/${classTypeId}/${studentId}`)
}

/**
 * 保存学生家庭作业答案
 * virtualClassId {number} 小班课Id
 * lessonId {number} 版本Id
 * studentId {number} 学生Id
 * dto {object} 传输数据
 */
const saveHomework = function(lessonId, studentId, dto) {
  return $post(`/api/homework/saveStudentHomeworkAnswer/${lessonId}/${studentId}`, dto)
}

/**
 * 提交学生家庭作业答案
 * virtualClassId {number} 小班课Id
 * classTypeId {number} 班级类型Id
 * lessonId {number} 版本Id
 * studentId {number} 学生Id
 * dto {object} 传输数据
 */
const submitHomework = function(virtualClassId, classTypeId, lessonId, studentId, dto) {
  return $post(`/api/homework/submitStudentHomeworkAnswer/${virtualClassId}/${classTypeId}/${lessonId}/${studentId}`, dto)
}

/**
 * 单个学生历史课堂详情
 * studentId {number} 学生Id
 * status {number} 课堂状态，3正常，4异常
 * virtualClassId {number} 课堂标识
 */
const virtualClassHistoryDetail = function(studentId, status, virtualClassId, classTypeId) {
  return $get(`/api/schedule/student/virtualClass/historyDetail/${studentId}/${status}/${virtualClassId}/${classTypeId}`)
}

/**
 * 多媒体做题查询接口
 */
const checkJunmpKidEditionHomework = function(virtualClassId, classTypeId, studentUserId, lessonId, lessonNo, menuSource) {
  return $get(`/api/homework/v2/lesson/student/${virtualClassId}/${classTypeId}/${studentUserId}/${lessonId}/${lessonNo}/${menuSource}`)
}

/**
 * 获取显示tab
 */
const getShowTabs = function (lessonId) {
  return $get(`/api/homework/type/${lessonId}`)
}

/**
 * 获取作业新接口
 */
// const getnewhomework = function (lessonId, classTypeId, virtualClassId, studentUserId, homeworkType) {
//   return $get(`/api/homework/student/${lessonId}/${classTypeId}/${virtualClassId}/${studentUserId}/${homeworkType}`)
// }

const getnewhomework = function (lessonId, classTypeId, virtualClassId, studentUserId, language, pageName="other") {
  return $get(`/homework/v3/student/info/${lessonId}/${classTypeId}/${virtualClassId}/${studentUserId}/${language}`,'',{
    name:`${pageName}获取课程作业接口request`
  })
}
/**
 * 获取作业分析结果
 */
const getHomeworkAnalysis =function(parentUserId, classTypeId, virtualClassId, studentUserId, lessonId) {
  return $get(`/api/homework/student/unit/homework/${parentUserId}/${classTypeId}/${virtualClassId}/${studentUserId}/${lessonId}`)
}
/**
 * 获取报告的url
 */
const getReportUrl =function(platForm,firstReportId,id) {
  return $get(`/api/course/urlSplit/${platForm}/${firstReportId}/${id}`)
}
/**
 * 获取单元报告的url 根据id区分返回不同的链接
 */
 const getunitReportUrl =function(unitReportId,language) {
  return $get(`/api/course/splicing/short/url/${unitReportId}/${language}`)
}
/**
 * 获取测评考试tab
 */
const getCpTab = function({lessonId,studentId}) {
  return $post(`/homework/lms/tab/all?lessonId=${lessonId}&studentId=${studentId}`)
}

/**
 * 获取绘本资源
 */
 const getSource = function({lessonId,studentId}) {
  return $post(`/homework/lms/get/source?lessonId=${lessonId}&studentId=${studentId}`)
}

/**
 * 绘本资源埋点
 */
 const sourcePoint = function(data) {
  return $post(`/preview/class/buried/point/data`,data)
}

/**
 * 获取测评考试详情
 */
 const getCpSource = function({lessonId,studentId,type}) {
  return $post(`/homework/lms/get/source/by/type?lessonId=${lessonId}&studentId=${studentId}&type=${type}`)
}
export default {
  getHomeworkList,
  checkHomeworkExist,
  createHomeworkDetail,
  getHomeworkDetail,
	getHomeworkTeacherFeedback,
  saveHomework,
  submitHomework,
  virtualClassHistoryDetail,
  checkJunmpKidEditionHomework,
  getShowTabs,
  getnewhomework,
  getHomeworkAnalysis,
  getReportUrl,
  getunitReportUrl,
  getCpTab,
  getSource,
  getCpSource,
  sourcePoint,
}
