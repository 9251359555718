var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-win",
      attrs: { visible: _vm.dialogVisible, width: "40%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        { staticClass: "desc-text" },
        [
          _vm.userAgentType
            ? [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("Your current browser is")) +
                    " " +
                    _vm._s(_vm.userAgentType) +
                    "，\n    "
                ),
              ]
            : [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("Your current browser is not a")) +
                    " Chrome " +
                    _vm._s(_vm.$t("browser")) +
                    "，\n    "
                ),
              ],
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("Please switch to the")) +
              " Chrome " +
              _vm._s(_vm.$t("browser")) +
              "\n  "
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("Confirm")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }