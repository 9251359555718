var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "top-warpper lg-container" }, [
    _c("div", { staticClass: "left", on: { click: _vm.contact } }, [
      _c("img", {
        attrs: {
          src: require("@/assets/theme-default/images/index/contactUs.png"),
          alt: "",
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "contact" }, [
        _vm._v(_vm._s(_vm.$t("Contact Us"))),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "right" }, [
      _c("ul", { staticClass: "user" }, [
        _c("li", [
          _c(
            "span",
            {
              staticClass: "local",
              class: { active: this.$i18n.locale == "en" },
              on: {
                click: function ($event) {
                  return _vm.setupLang("en")
                },
              },
            },
            [_vm._v("ENG")]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "local",
              class: { active: this.$i18n.locale == "zh" },
              on: {
                click: function ($event) {
                  return _vm.setupLang("zh")
                },
              },
            },
            [_vm._v("中文")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "areaTime",
            style: { width: _vm.areaTimeWidth },
            attrs: { id: "areaTime" },
          },
          [_vm._v(_vm._s(_vm.areaTime))]
        ),
        _vm._v(" "),
        _c("li", [_c("Notice", { attrs: { count: 1 } })], 1),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("Avatar", {
              attrs: { avatarUrl: _vm.avatarUrl, username: _vm.user.username },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }