import GetData from '@/utils/axios.js'
const { $get, $post,$put } = new GetData();
/* 消费流水 */
const consume = ({ userId, pageNum = 1, pageSize = 10 }) => {
  return $get(`/api/finance/parent/consumer/${userId}/${pageNum}/${pageSize}`);
}
/* 充值流水 */
const topup = ({ userId, pageNum = 1, pageSize = 10 }) => {
  return $get(`/api/finance/parent/recharge/${userId}/${pageNum}/${pageSize}`);
}
/* 获取优惠列表 */
const coupon = ({ userId, status }) => {
  return $get(`/api/finance/coupon/code/${userId}/${status}`);
}
/* 添加优惠券 */
const addCoupon = ({ userId, code }) => {
  return $post(`/api/finance/coupon/code/${userId}/${code}`);
}
/* 可用货币 */
const currency = (packageId,currentCountry ) => {
  return $get(`/api/finance/recharge/currency?productPackageId=${packageId}&country=${currentCountry}`);
}


/* 常用充值课时列表 */
const recharge = () => {
  return $get(`/api/finance/recharge/discount`);
}
/* 确认订单 */
const confirmRecharge = ({ userId, code, productPackageId, currencyId, confirmBill, rechargeAmount }) => {
  return $post(`/api/finance/recharge/billing/info/${userId}`, { couponCode: code, productPackageId, currencyId, confirmBill, rechargeAmount });
}
/* 确认订单小班课 */
const confirmRechargeSgClassId = ({ userId, code, currencyId, sgClassId, confirmBill, rechargeAmount }) => {
  return $post(`/api/finance/recharge/billing/info/${userId}`, { couponCode: code, currencyId, sgClassId, confirmBill, rechargeAmount });
}
/* 确认订单小班课 */
const confirmRechargePackage = ({ userId, code, currencyId, sgClassId, confirmBill, rechargeAmount, rechargePackageId }) => {
  return $post(`/api/finance/recharge/billing/info/${userId}`, { couponCode: code, sgClassId, confirmBill, rechargeAmount, currencyId, rechargePackageId });
}
/* 小班课团购 */
const confirmRechargeMiniClass = ({ userId, code, productPackageId, currencyId, confirmBill, rechargeAmount, groupId, showState }) => {
  return $post(`/api/finance/recharge/billing/info/${userId}`, { couponCode: code, productPackageId, currencyId, confirmBill, rechargeAmount, groupId, showState });
}
/* 添加银行卡 */
const addCards = (userId, info) => {
  return $post(`/api/finance/recharge/stripe/pay/method/${userId}`, info);
}
/* 获取支付方式 */
const getCards = (userId) => {
  return $get(`/api/finance/recharge/stripe/pay/method/${userId}`);
}
/* 通过stripe支付 */
const pay = (userId, payInfo) => {
  return $post(`/api/finance/recharge/order/stripe/pay/${userId}`, payInfo);
}
/* 银行卡支付 */
const cardPay = (userId, payInfo) => {
  return $post(`/api/pay/order/create/card/${userId}`, payInfo);
}
/* 支付宝支付 */
const aliPay = (userId, payInfo) => {
  return $post(`/api/pay/order/create/alipay/${userId}`, payInfo);
}
/* 微信支付 */
const weixinPay = (userId, payInfo) => {
  return $post(`/api/pay/order/create/wechat/${userId}`, payInfo);
}
/* 监测支付状态 */
const observePayStatus = (orderNo) => {
  return $get(`/api/pay/order/status/${orderNo}`);
}
const getSecretKey = (currency) => {
  return $get(`/api/pay/stripe/pubkey/${currency}`)
}
// 创建订单接口
const createOrder = ({ userId, studentUserId, code, currencyId, productPackageId, rechargeAmount, rechargeType, sgClassId, rechargePackageId, groupId, showState ,groupBuyCode}, pageName="other") => {
  return $post(`/api/finance/recharge/order/${userId}`, { couponCode: code, studentUserId, currencyId, rechargeAmount, productPackageId, rechargeType, sgClassId, rechargePackageId, groupId, showState, groupBuyCode },{
    name:`${pageName}创建订单接口request`
  });
}

// 获取银行卡支付时 clientSecret 和 key  && klarna支付
const clientSecret = ({ channel, orderId, type, params }) => {
  return $post(`/api/pay/order/payment/${channel}/${orderId}/${type}`, params)
}

// 3.11 Atome 分期
const getAtomeBaseInfo = ({ channel, orderId, type, params }) => {
  return $post(`/api/pay/order/payment/${channel}/${orderId}/${type}`, params)
}
//Affirm 分期
const getAffirmBaseInfo = ({ channel, orderId, type, params }) => {
  return $post(`/api/pay/order/payment/${channel}/${orderId}/${type}`, params)
}
//查询家长手机号
const getparentPhone = ({parentId}) => {
  return $get(`/api/user/parent/get/phone?parentId=${parentId}`)
}

//更新家长手机号
const putparentPhone = (params) => {
  return $put(`/api/user/parent/supply/phone`,params)
}
/* 3.6改造充值页面获取优惠列表 */
// const getAllCoupon = ({ userId, accountClass, amount }) => {
//   return $get(`/api/finance/coupon/code/${userId}/${accountClass}/${amount}`);
// }

/* 系统优化，升级改造 */
const getAllCoupon = ({ userId, productId, amount, productPackageId, currency}) => {
  let query = `?${productPackageId ? 'productPackageId=' + productPackageId : ''}${currency ? '&currency=' + currency : ''} `
  query = query === '?' ? '' : query
  return $get(`/api/finance/coupon/code/product/${userId}/${productId}/${amount}${query}`);
}

/* 3.6改造获取银行卡支付结果 */
const getPayResult = ({ paymentId }) => {
  return $get(`/api/pay/order/payment/result/${paymentId}`);
}

/* 3.6改造申请收据 */
const applayOrderReceive = ({ orderId }) => {
  return $get(`/api/order/apply/receipt/${orderId}`);
}

/* 3.6改造根据家长账户id查询标准课时定向课时信息 */
const getAccountLessons = ({ userId }) => {
  return $get(`/api/lesson/count/${userId}`);
}

// 获取学生剩余课时
const getStudentAccounts = ({userId}) => {
  return $get(`/api/lesson/student/count/${userId}`);
}

/* 4.0改造  */
const getProductList = () => {
  return $get(`/api/product/pay/list`);
}

/* 3.6改造 获取支付成功订单详情 */
const getOrderDetailBySucc = ({ orderId }) => {
  return $get(`/api/order/detail/success/${orderId}`);
}

/* 3.6改造 获取支付结果详情 */
const getOrderPayResultDetail = ({ paymentId, status }) => {
  return $get(`/api/pay/detail/${paymentId}/${status}`);
}

/* 3.6改造 获取顾问信息 */
const getAdviserInfo = ({ userId }) => {
  return $get(`/api/user/userInfo/${userId}`);
}

/* 3.7改造 获取地理位置信息 */
const getLocation = () => {
  return $get('/api/user/potential/country')
}

const getCountry = () => {
  return $get('/api/common/country/list')
}

/* 3.8.0.1改造 获取地理信息 */
const getCountryInfo = () => {
  return $get('/api/common/country')
}

/**
 * @description  政策风险规避 不可以+86用户注册
 */

const getCountryRegister = () => {
  return $get('/api/common/country/part/list')
}
// 单一渠道查询
const getAppldChannel =(params) =>{
 return $get(`/api/common/campaign/channel`,params)
}
//获取二维码链接接口
const getLinkUrl =(data) =>{
  return $post(`/campaign/transparent/api`,data)
 }
/* 4.0改造 产品余额信息列表 */
const getProductFinance = ({ userId }) => {
  return $get(`/api/product/finance/detail/list/${userId}`)
}

const getStrategyInfo = ({decisionCode,paramMap}) => {
  return $post(`/api/strategy/policy`,{decisionCode,paramMap})
}

/*判断课包是否有效 */
const getProductStatus = (params) => {
  return $get(`/api/product/finance/product/status`,params)
}

const getFirstOrder = ({ parentUserId, orderNum}) => {
  return $get(`/campaign/client/order/check/subject/first/${parentUserId}/${orderNum}`)
}

const getBannerInfo = (params) => {
  return $post(`/rlm/resouce-list-v2`, params)
}

export default {
  getLinkUrl,
  consume,
  topup,
  coupon,
  addCoupon,
  currency,
  recharge,
  confirmRecharge,
  addCards,
  getCards,
  pay,
  cardPay,
  aliPay,
  weixinPay,
  observePayStatus,
  getSecretKey,
  confirmRechargeSgClassId,
  confirmRechargePackage,
  confirmRechargeMiniClass,
  createOrder,
  clientSecret,
  getAtomeBaseInfo,
  getAllCoupon,
  getPayResult,
  applayOrderReceive,
  getAccountLessons,
  getStudentAccounts,
  getProductList,
  getOrderDetailBySucc,
  getOrderPayResultDetail,
  getAdviserInfo,
  getLocation,
  getCountryInfo,
  getProductFinance,
  getCountry,
  getCountryRegister,
  getAffirmBaseInfo,
  getStrategyInfo,
  getAppldChannel,
  getparentPhone,
  putparentPhone,
  getProductStatus,
  getFirstOrder,
  getBannerInfo
}
