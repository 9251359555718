<template>
  <div id="app">
    <div style="min-height:100%">
      <index v-if="islogin"></index>
    </div>
    <div class="copyright">Copyright © 2017-{{year}} LingoAce</div>
    <NavigatorTip/>
  </div>
</template>
<script>
import index from '@/components/index/index'
import NavigatorTip from '@/components/common/NavigatorTip'
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    //router-view 在index组件中 routerBefore 不能完全控制  其他组件中会调取接口导致报错 添加v-if判断
    ...mapGetters({islogin:'user/getIsLogin'}),
  },
  created() {
   // 本地多语言图片加载
    Vue.prototype.$loadImage=function(name,type = this.$i18n.locale) {
      try {
        return require(`@/images/${type}/${name}`)
      } catch (error) {
        console.log(error);
        return require(`@/images/en/${name}`)
      }
    }
  },
  components: {
    index,
    NavigatorTip,
  }
};

</script>
<style>
/*homework*/
.submit-dialog .el-dialog__header,
.result-dialog .el-dialog__header {
  padding: 0;
}

.hw-no-work {
  color: #EB532F;
}

.refer-input .el-input__inner {
  width: 260px;
}

/* 学习菜单 转场动画 animation*/
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  /*transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);*/
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

</style>
