import Vue from 'vue'
import VueI18n from '@lingoace/vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import ElementLocale from 'element-ui/lib/locale'
import DatePicker from 'element-ui/lib/date-picker'
import Pagination from 'element-ui/lib/pagination'
import {changeLang} from "@lingoace/account-lingoace"
import { getLanguage } from "@lingoace/environment-lingoace";
import getUrlParams from '@/utils/getUrlParams';
import api from '@/api'
Vue.use(VueI18n)
Vue.use(DatePicker)
Vue.use(Pagination)

// 维护在全局的变量 方便各个地方调用
export var curlang = 'zh';
const DEFAULT_LANG = 'zh'
const LOCALE_KEY = 'localeLanguage'

// 官网跳转  url控制 languageTag进行控制
let urlParamsLang = getUrlParams()?.languageTag;
if (urlParamsLang) {
  urlParamsLang=urlParamsLang.includes('zh')?'zh':'en';
}

const locales = {
  zh: {
    ...require('./zh.json'),
    ...zhLocale
  },
  en: {
    ...require('./en.json'),
    ...enLocale
  },
}

const i18n = new VueI18n({
  locale: urlParamsLang||DEFAULT_LANG,
  messages: locales,
})
ElementLocale.i18n((key, value) => i18n.t(key, value))
export const setup = async lang => {
  let {data} = await api.user.getCourseName()
  if (data.code==200) {
    let courseEditionEnMap=new Map()
    let courseEditionZhMap=new Map()
    data.data.forEach(ele => {
      courseEditionZhMap.set(ele.id,ele.editionNameCZh)
      courseEditionEnMap.set(ele.id,ele.editionNameCEn)
    });
    Vue.filter('levelTitle', function(value) {
      let levelTitle=lang=='en'?courseEditionEnMap.get(+value):courseEditionZhMap.get(+value)
      return levelTitle?levelTitle:(lang == 'en'? 'Chinese': '中文')
    });
  }
  if (lang === undefined) {
    lang = window.localStorage.getItem(LOCALE_KEY) || DEFAULT_LANG
  }
  window.localStorage.setItem(LOCALE_KEY, lang)
  lang=='zh'?changeLang('zh-CN'):changeLang('en')
  console.log('lang',lang);

  Object.keys(locales).forEach(lang => {
    document.body.classList.remove(`lang-${lang}`)
  })
  document.body.classList.add(`lang-${lang}`)
  document.body.setAttribute('lang', lang)

  Vue.config.lang = lang
  i18n.locale = lang
  curlang = lang
}
var lang = getLanguage().includes('en')?'en':'zh';

setup(urlParamsLang||localStorage.getItem(LOCALE_KEY) || lang)
export default i18n
