var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    {
      attrs: {
        "default-active": _vm.activeIndex,
        "text-color": "#666666",
        "active-text-color": "#FFFFFF",
      },
      on: { select: _vm.selectMenu },
    },
    _vm._l(_vm.menus, function (item, $key) {
      return _c(
        "el-menu-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                (_vm.memberRole != _vm.ApiConstants.classMemberRoleExit &&
                  $key == _vm.menus.length - 1) ||
                $key < _vm.menus.length - 1,
              expression:
                "(memberRole!=ApiConstants.classMemberRoleExit&&$key==menus.length-1) || $key<menus.length-1",
            },
          ],
          key: $key,
          attrs: { disabled: item.isEnable, index: item.href },
        },
        [
          _vm.getCurrentCountry === "indonesia" && item.href === "goodCourse"
            ? _c("div", { staticClass: "dot" })
            : _vm._e(),
          _vm._v(" "),
          _c("i", { class: ["mg-icon", item.icon] }),
          _vm._v(" "),
          _c("span", { domProps: { innerHTML: _vm._s(item.title) } }),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }