var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "lg-container main" },
    [
      _c(
        "el-col",
        { staticClass: "left", style: _vm.positionFixed, attrs: { span: 3 } },
        [
          _vm.students !== null
            ? _c(
                "div",
                { staticClass: "students-warpper" },
                [_c("Students")],
                1
              )
            : _c("div", {
                directives: [{ name: "loading", rawName: "v-loading" }],
                staticClass: "no-students-wrapper",
              }),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "left-menu-warpper",
              attrs: { "body-style": { padding: "0px", width: "" } },
            },
            [_c("LeftMenu", { on: { selectMenu: _vm.selectMenu } })],
            1
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "it-test-warpper",
              attrs: { "body-style": { padding: "5px 20px" } },
            },
            [
              _vm.isShowHot
                ? _c("i", { staticClass: "notice-ittest" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "https://global.talk-cloud.net/static/h5_new_devicetest/index.html#/?domain=oks&isDeviceCallback=1&pid=" +
                      _vm.currentStudent.id,
                    target: "_blank",
                  },
                },
                [
                  _c("i", { staticClass: "mg-icon icon-ittest" }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "title", on: { click: _vm.getIsShowHot } },
                    [_vm._v(_vm._s(_vm.$t("IT Test")))]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "friend-warpper",
              attrs: { "body-style": { padding: "13px 12px" } },
            },
            [
              _c(
                "a",
                {
                  attrs: { href: "javascript:;" },
                  on: { click: _vm.changeRouteRef },
                },
                [
                  _c("i", { staticClass: "mg-icon icon-friend" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("Refer friend"))),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-col",
        { staticClass: "right", attrs: { span: 19 } },
        [
          _c(
            "transition",
            { attrs: { name: "slide-fade" } },
            [_c("router-view", { staticClass: "child-view" })],
            1
          ),
          _vm._v(" "),
          _c("AddStudent", {
            attrs: {
              open: _vm.isOpen,
              info: { parentId: _vm.getUserInfo.userParentInfo.id },
            },
            on: {
              close: function ($event) {
                _vm.isOpen = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }