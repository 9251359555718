<template>
  <div
    class="page-class-detail"
    v-loading="pageLoading"
    element-loading-spinner="custom-loading"
  >
    <div class="dash">
      <div v-for="(item, index) in lessonsInfo" :key="item.id">
        <div
          class="dash-item"
          :class="{ 'dash-item-active': currentTabIndex == index }"
          @click="changeCurrentTabIndex(index)"
        >
          <div class="line-1">
            {{ $i18n.locale == "en" ? item.productNameEn : item.productName }}
          </div>
          <div class="line-2" v-if="lessonsInfo">
            {{ item.totalRemainingAmount || 0 }}
          </div>
          <div
            class="line-3"
            v-if="currentTabIndex == index && lessonsInfo"
          ></div>
        </div>
      </div>
    </div>
    <div class="filter-box" tabindex="0" @blur="filterBlur()">
      <div class="filter" @click="filterActiveChange">
        <div v-if="currentFilterCondition == 3">{{ $t("Recent 3 month") }}</div>
        <div v-if="currentFilterCondition == 6">{{ $t("Recent 6 month") }}</div>
        <div><img src="@/images/order/arrow_down.png" /></div>
      </div>
      <div class="filter-item" v-if="filterItemShow">
        <div
          class="filter-item-single"
          :class="{ 'filter-item-single-active': currentFilterCondition == 3 }"
          @click="changeCurrentFilterConditon(3)"
        >
          {{ $t("Recent 3 month") }}
        </div>
        <div
          class="filter-item-single"
          :class="{ 'filter-item-single-active': currentFilterCondition == 6 }"
          @click="changeCurrentFilterConditon(6)"
        >
          {{ $t("Recent 6 month") }}
        </div>
      </div>
    </div>
    <div class="list">
      <div>
        <div class="list-item" v-for="(i, index) in listData" :key="index">
          <div class="line-1">
            <div class="line-1-l">
              {{ i.createTime | orderCreateDateToFilter }}
            </div>
            <div class="line-1-r">
              <span :class="{'line-1-red' : ispush(i.amountChange)}">{{ i.amountChange }}</span>
            </div>
          </div>
          <div class="line-2">
            <div class="item item-1">
              <div class="item-1-1">
                <div v-if="i.reason == 1">{{ i.studentName }}</div>
                <div v-else>
                  {{ $i18n.locale == "zh" ? i.descriptionZn : i.descriptionEn }}
                </div>
              </div>
            </div>
            <div class="item item-2">
              <!-- <div class="item-3-1" v-if="i.reason == 1 || i.reason == 13 || i.reason == 14 ">{{$t(levelTitle(i.courseEditionId))}} Level{{i.courseLevel}} Lesson{{i.lessonNo}}</div> -->
              <div
                class="item-3-1"
                v-if="i.reason == 1 || i.reason == 13 || i.reason == 14"
              >
                {{ $t(levelTitle(i.courseEditionId)) }}
                {{ i.lessonDescription }}
              </div>
            </div>
            <img
              v-if="
                i.classTypeId === 2 &&
                i.amount < 0 &&
                (i.reason == 1 || i.reason == 13 || i.reason == 14)
              "
              class="icon-group"
              src="@/images/order/icon-group.png"
            />
            <div class="item item-3">
              <div
                class="item-3-1"
                v-if="i.reason == 1 || i.reason == 13 || i.reason == 14"
              >
                {{ i.teacherName }}
              </div>
            </div>
            <div class="item item-4">
              <div class="item-4-1">
                {{ $i18n.locale == "zh" ? i.descriptionZn : i.descriptionEn }}
              </div>
            </div>
          </div>
          <!-- 课时有效期 -->
          <!-- <div class="line-3">
            <div class="time" v-if="i.amount > 0">
              <div v-if="i.duration == 100">
                {{$t("Points available period")}}{{$t("Perpetual")}}
              </div>
              <div v-else>
                {{$t("Points available period")}}{{i.duration}} {{getTimeType(i)}}
              </div>
            </div>
          </div> -->
          <div class="line-3">
            <div class="time" v-if="i.amount > 0">
              <div v-if="i.duration == 100">
                {{$t("Points available before")}}{{$t("No expiration date")}}
              </div>
              <div v-else>
                {{$t("Points available before")}}{{DateTimeUtils.formartDateFromUTC(i.endTime,'yyyy-MM-dd hh:mm')}}
              </div>
            </div>
          </div>
        </div>
        <div
          class="see-more"
          v-if="!pageLoading && !allLoaded"
          @click="loadListData"
        >
          {{ $t("SEE MORE") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * @description  reson
 *  --------------------------------  参考作用  具体以ops配置为准  --------------------------------
 * ONE(1, "上课消耗","Class attended"),
 * TWO(2, "购买课程包","Purchase package"),
 * THREE(3, "用户充值","Obtained by Classes added"),
 * FOUR(4, "充值奖励","Top-up bonus"),
 * FIVE(5, "推荐人奖励","Bonus for recommended friends"),
 * SIX(6, "免费试听","Free trial class sent by the system"),
 * TEN(10, "课时转入","Transfer in"),
 * ELEVEN(11, "课时转出","Transfer out"),
 * TWELVE(12, "系统补偿","Compensation of system"),
 * THIRTEEN(13, "学生缺席罚款","Penalty on students absence"),
 * FOURTEEN(14, "老师缺席补偿","Compensation for teachers absence"),
 * FIFTEEN(15, "退款","Refund"),
 * SIXTEEN(16, "补偿","Compensation"),
 * EIGHTEEN(18, "被推荐人充值","Bonus for recommended friend's top-up"),
 * NINETEEN(19, "被推荐人完成试听","recommended friend's trial class"),
 * TWENTY(20, "合并账号-进账","Merge account - income"),
 * TWENTY_ONE(21, "合并账号-出账","Merge account - out of account"),
 * TWENTY_TWO(22, "手动定级赠送","Free trial class sent by the system"),
 * TWENTY_THREE(23, "凌晨补助","Early morning teaching subsidy"),
 * TWENTY_FOUR(24, "兑换LingoAce积分","Redeem LingoAce Rewards Tokens"),
 * TWENTY_SEVEN(27, "课时兑换积分","Exchanged to Aces"),
 * TWENTY_EIGHT(28, "积分兑换课时","Exchanged to Points"),
 * ONE_HUNDRED_AND_TWELVE(112, "家教免费课","Free class"),
 * ONE_HUNDRED_AND_THIRTEEN(113, "学生缺席老师奖励","Teacher reward for students' absence"),
 * ONE_HUNDRED_AND_FOURTEEN(114, "对自己不上课的惩罚","Not attending class"),
 * ONE_HUNDRED_AND_FIFTEEN(115, "由青少版1V1课时转换","Transfer from Youths 1v1"),
 * ONE_HUNDRED_AND_SIXTEEN(116, "转换为启蒙版1v1课时","Transfer to Kids 1v1"),
 */
import { mapGetters } from 'vuex'
export default {
  name: 'classDetail',
  data() {
    return {
      allLoaded: false,
      pageLoading: false,
      filterItemShow: false,
      currentTabIndex: 0,
      currentFilterCondition: 3,
      currentProductId: null,
      coursePackageId: null,
      listData: [],
      standardClassListData: [],
      specialClassListData: [],
      pathParameter: {
        pageNum: 1,
        pageSize: 10,
        parentUserId: null,
        type: null,
        startTime: moment().subtract(3, "months").format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
      },
      lessonsInfo: null,
      userParentInfo: this.$store.state.user.user.userParentInfo
    }
  },

  created() {
    // this.currentTabIndex = this.$route.query.type ? this.$route.query.type : 1
  },

  mounted() {
    this.getAccountLessons()
  },

  computed: {
    ...mapGetters({
      parentInfo: 'user/getParentInfo'
    }),
  },

  methods: {
    ispush(num) {
      if(num.charAt(0) === "+") {
        return true
      }else {
        return false
      }
    },
    getTimeType(item) {
      if(item.typeOfDuration == 0) {
        if( item.duration > 1) {
          return this.$t("Days")
        } else {
          return this.$t("day")
        }
      }else if(item.typeOfDuration == 1) {
        if(item.duration > 1) {
          return this.$t("months")
        } else {
          return this.$t("month")
        }
      }else if(item.typeOfDuration == 2) {
        if(item.duration > 1) {
          return this.$t("years")
        } else {
          return this.$t("year")
        }
      }
    },
    levelTitle(courseEditionId) {
      let filter = Vue.filter('levelTitle')
      return filter(courseEditionId);
    },
    filterActiveChange() {
      this.filterItemShow = !this.filterItemShow
    },
    filterBlur() {
      this.filterItemShow = false
    },
    changeCurrentFilterConditon(e) {
      this.filterItemShow = false
      this.currentFilterCondition = e
      this.pathParameter.startTime = moment().subtract(e, "months").format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
      this.allLoaded = false
      this.pathParameter.pageNum = 1
      this.listData = []
      this.loadListData()
    },
    loadListData() {
      this.pageLoading = true
      let params = {
        startTime: this.pathParameter.startTime,
        productId: this.currentProductId,
      }
      if(this.coursePackageId) {
        params = {
          ...params,
          coursePackageId: this.coursePackageId,
        }
      }
      this.$api.order.getLessonList(this.pathParameter.pageNum, this.pathParameter.pageSize, this.parentInfo.id, params).then(res => {
        this.pageLoading = false
        if (res.status === 200 && res.data.code === 200) {
          this.allLoaded = res.data.data.pageNum >= res.data.data.totalPage
          this.listData = this.listData.concat(res.data.data.list)
          this.pathParameter.pageNum++
        } else {
          this.$message(res.data.message)
        }
      })
    },
    changeCurrentTabIndex(index) {
      this.currentTabIndex = index
      this.currentProductId = this.lessonsInfo[index].productId
      this.coursePackageId = this.lessonsInfo[index].coursePackageId;
      this.allLoaded = false
      this.pathParameter.pageNum = 1
      this.listData = []
      this.loadListData()
    },
    getAccountLessons() {
      const userId = this.userParentInfo.id
      this.$api.finance.getProductFinance({ userId }).then(res => {
        if (res && res.data && res.data.code === 200) {
          this.lessonsInfo = res.data.data
          if (this.lessonsInfo.length) {
            this.currentProductId = this.lessonsInfo[0].productId
            this.coursePackageId = this.lessonsInfo[0].coursePackageId
            this.loadListData()
          }
        } else {
          this.$message({
            message: res.data.message,
            center: true
          });
        }
      }, err => {
        console.log('err', err)
      })
    },
  }

}
</script>
<style scoped lang="stylus">
* {
  box-sizing: border-box;
}

.page-class-detail {
  height: 100%;

  .dash {
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 4px rgba(182, 182, 182, 0.08);
    border-radius: 14px;
    margin-bottom: 20px;
    padding: 20px 50px;
    display: flex;
    overflow-x: scroll;

    .dash-item {
      min-width: 120px;
      text-align: center;
      margin-right: 50px;
      cursor: pointer;
      color: #34364D;

      .line-1 {
        font-size: 20px;
        padding-top: 20px;
        margin-bottom: 15px;
      }

      .line-2 {
        font-size: 30px;
        margin-bottom: 10px;
      }

      .line-3 {
        height: 4px;
        width: 100px;
        margin: 0 auto;
        background: #84D6D6;
      }

      &.dash-item-active {
        color: #84D6D6;
      }
    }
  }

  .filter-box {
    position: relative;
    min-width: 160px;
    display: inline-block;

    .filter {
      margin-bottom: 20px;
      min-width: 160px;
      height: 40px;
      background: #fff;
      border-radius: 48px;
      border: 1px solid #7580E5;
      padding: 0 26px;
      display: flex;
      justify-content: space-between;
      line-height: 40px;
      color: #7580E5;
      cursor: pointer;

      img {
        width: 20px;
        height: 10px;
        margin-left: 5px;

      }
    }

    .filter-item {
      position: absolute;
      top: 50px;
      left: 0;
      min-width: 160px;
      height: 80px;
      border: 1px solid #7580E5;
      border-radius: 14px;
      background: #fff;
      padding-top: 10px;

      .filter-item-single {
        height: 34px;
        font-size: 14px;
        line-height: 34px;
        padding: 0 20px;
        color: #7580E5;
        cursor: pointer;

        &.filter-item-single-active {
          color: #fff;
          background: #7580E5;

          &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
  }

  .list {
    .list-item {
      min-height: 110px;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 4px rgba(182, 182, 182, 0.08);
      border-radius: 14px;
      margin-bottom: 20px;
      padding: 0 30px 20px;

      .line-1 {
        display: flex;
        justify-content: space-between;
        line-height: 40px;
        border-bottom: 2px solid #DEDEDE;
        color: #999;

        .line-1-l {
          color: #666;
        }
        .line-1-red {
          color: #f891a5
        }
      }

      .line-2 {
        display: flex;

        .item {
          width: 25%;
          padding-top: 18px;

          &.item-1 {
            .item-1-1 {
              font-size: 18px;
              color: #34364D;
              width: 220px;

              img {
                width: 60px;
                vertical-align: top;
                margin-top: -3px;
              }
            }

            .item-1-2 {
              font-size: 14px;
              color: #666;
              margin-top: 6px;
            }
          }

          &.item-2 {
            text-align: center;
            width :364px

            .item-2-1 {
              color: #34364D;
              margin-bottom: 10px;
              font-size: 18px;
            }

            .item-2-2 {
              color: #999;
              font-size: 14px;
              text-decoration: line-through;
            }
          }

          &.item-3 {
            text-align: center;

            .item-3-1 {
              color: #34364D;
              font-size: 18px;
              margin-bottom: 10px;
              width: 280px;
            }

            .item-3-2 {
              color: #999;
              font-size: 14px;
            }
          }

          &.item-4 {
            display: flex;
            justify-content: flex-end;

            .item-4-1 {
              color: #666;
              font-size: 14px;
            }
          }
        }
      }
      .line-3 {
        .time {
          padding-top: 18px;
          font-size: 18px;
          color: #34364D;
          width :100%;
        }
      }
    }
  }

  .see-more {
    border: 2px solid #FFA13C;
    width: 150px;
    height: 30px;
    background: #FFFFFF;
    line-height: 30px;
    text-align: center;
    border-radius: 15px;
    font-size: 16px;
    color: #FFA13C;
    cursor: pointer;
    margin: 0 auto;
    font-family: SofiaProSoftRegular;

    &:hover {
      color: #fff;
      background: #FFA13C;
      transition: all .25s linear;
    }
  }
}

.icon-group {
  width: 64px;
  height: 24px;
  margin-top: 15px;
}
</style>
