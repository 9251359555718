var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "index-warpper" },
    [
      _c(
        "div",
        { staticClass: "l-header" },
        [
          _c("TopBar"),
          _vm._v(" "),
          _c("TopMenu", { on: { selectMenu: _vm.selectMenu } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm.tipType == 1 && _vm.$route.path.indexOf("/home") == 0
            ? _c("div", { staticClass: "tip" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("Your Policy Email was resent!"))),
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "colse-img",
                  attrs: {
                    src: require("@/assets/theme-default/images/index/close-img.png"),
                    alt: "",
                  },
                  on: { click: _vm.colseTip },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.tipType == 2
            ? _c("div", { staticClass: "tip del" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: require("@/assets/theme-default/images/usercenter/ic.png"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("Your account is pending deletion"))),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tip-warpper" },
            [
              _vm.user.userParentInfo.balance < 5 && !_vm.isNewUser
                ? _c("ToUpTip")
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "main-warpper" }, [_c("router-view")], 1),
          _vm._v(" "),
          _c("AddStudent", {
            attrs: {
              open: _vm.isOpen,
              canClose: !!this.currentStudent.realName,
            },
            on: {
              close: function ($event) {
                _vm.isOpen = false
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("BackToTop"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }