import GetData from '@/utils/axios.js'
const { $get, $post } = new GetData()
/**
 * 课堂
 * @param {Object} params 参数对象，包括：
 *        userId          {String} 用户名
 *        startTime       {Date} 课表开始时间
 *        endTime         {Date} 课表结束时间
 */
const tutorTimetable = function(userId, startTime, endTime) {
  return $get('/api/schedule/tutor/timetable/' + userId + '/' + startTime + '/' + endTime)
}

const tutorPublishTimeable = function(userId, startTime, endTime, pageName='other') {
  return $get('/api/schedule/tutor/timetable/publish/' + userId + '/' + startTime + '/' + endTime,'',{
    name:`${pageName}获取当前老师可以约课的时间集合request`
  })
}

/**
 * 课堂信息列表
 * @param {Object} params 参数对象，包括：
 *        userId          {String} 用户名
 *        history         {Int} 课堂类型：0未开始 1已开始
 *        pageNum         {Int} 页数
 *        pageSize        {Int} 每页数目
 */
const getVirtualclass = function(userId, history, pageNum, pageSize) {
  return $get('/api/schedule/student/virtualclass/' + userId + '/' + history + '/' + pageNum + '/' + pageSize)
}

const getComingVirtualclass = function(userId, pageSize,pageName='Home页') {
  return $get('/api/schedule/student/virtualclass/' + userId + '/' + pageSize,'',{
    name:`${pageName}待上课程列表request`
  })
}

/**
 * 课表信息列表
 * @param {Object} params 参数对象，包括：
 *        userId          {String} 用户名
 *        history         {Int} 课堂类型：0未开始 1已开始
 *        pageNum         {Int} 页数
 *        pageSize        {Int} 每页数目
 */
const getSchedule = function(userId, startTime, endTime, subjectType) {
  let params = '';
  if(subjectType != -1){
    params += `?subjectType=${subjectType}`
  }
  return $get(`/api/schedule/student/timetable/${userId}/${startTime}/${endTime}${params}`)
}

const getScheduleByDivpage = function(userId, pageNum, pageSize, startTime, subjectType) {
  let params = '';
  if(subjectType != -1){
    params += `&subjectType=${subjectType}`
  }
  return $get(`/api/schedule/student/timetable/list/${userId}/${pageNum}/${pageSize}?startTime=${startTime}${params}`)
}

const frequentlyUsedTeachers = (productId, userId, classTypeId, userEligibleTutorParam, pageNum = 1, pageSize = 100) => {
  if (userEligibleTutorParam) {
    return $get(`/api/schedule/tutor/frequentV2/${productId}/${userId}/${classTypeId}/${pageNum}/${pageSize}`, {
      preferTime: userEligibleTutorParam
    })
  } else {
    return $get(`/api/schedule/tutor/frequentV2/${productId}/${userId}/${classTypeId}/${pageNum}/${pageSize}`)
  }
}

const otherRecommendedTeachers = (productId, userId, classTypeId, userEligibleTutorParam, pageNum = 1, pageSize = 3) => {
  if (userEligibleTutorParam) {
    return $get(`/api/schedule/tutor/eligibleV2/${productId}/${userId}/${classTypeId}/${pageNum}/${pageSize}`, {
      preferTime: userEligibleTutorParam
    });
  } else {
    return $get(`/api/schedule/tutor/eligibleV2/${productId}/${userId}/${classTypeId}/${pageNum}/${pageSize}`);
  }

}
/**
 * @Author   Yaodongxin
 * @DateTime 2019-12-10
 * @method   memberRole  查询学员的角色（班长||普通学员）
 * @param    {string}   userId 学员id
 */
const memberRole = (userId) => {
  return $get(`/api/schedule/student/memberRole/${userId}`);
}

const joinedClass = (userId) => {
  return $get(`/api/classroom/joinedClass/${userId}`)
}

// 是否可约课
const checkSubscribe = function(params) {
  const {studentUserId, coursePackageId} = params;
  return $get(`/api/schedule/student/checkSubscribe?studentUserId=${studentUserId}&coursePackageId=${coursePackageId}`)
}

// 获取学生当前进度课包中的课程时长
const getLessonDuration = function(params) {
  const {studentUserId, coursePackageId} = params;
  return $get(`/api/schedule/student/lessonDuration/${studentUserId}/${coursePackageId}`)
}
const getCancelReasont = function() {
  return $get(`/api/classroom/oto/class/cancelreason/config`)
}
export default {
  tutorTimetable,
  tutorPublishTimeable,
  getVirtualclass,
  getComingVirtualclass,
  frequentlyUsedTeachers,
  getSchedule,
  getScheduleByDivpage,
  otherRecommendedTeachers,
  memberRole,
  joinedClass,
  checkSubscribe,
  getLessonDuration,
  getCancelReasont,
}
