var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "lg-container topmenu" }, [
    _c("li", { staticClass: "logo" }, [
      _c(
        "a",
        {
          attrs: { href: "javascript:;" },
          on: {
            click: function ($event) {
              return _vm.selectMenu("/home")
            },
          },
        },
        [_c("img", { attrs: { src: _vm.logoUrl, alt: "" } })]
      ),
    ]),
    _vm._v(" "),
    _c(
      "li",
      {
        staticClass: "title",
        class: { active: _vm.$route.path.indexOf("/home") == 0 },
        on: {
          click: function ($event) {
            return _vm.selectMenu("/home")
          },
        },
      },
      [_vm._v(_vm._s(_vm.$t("Study")))]
    ),
    _vm._v(" "),
    _c(
      "li",
      {
        staticClass: "points",
        class: { active: _vm.$route.path.indexOf("/points") == 0 },
        staticStyle: { height: "100%" },
        on: {
          click: function ($event) {
            return _vm.selectMenu("/points")
          },
        },
      },
      [_vm._v("\n      " + _vm._s(_vm.$t("Points")) + "\n    ")]
    ),
    _vm._v(" "),
    _c(
      "li",
      {
        staticClass: "refer-friends",
        class: {
          active:
            _vm.$route.path.indexOf("/referfriends") == 0 ||
            _vm.$route.path.indexOf("/aceTip") == 0,
        },
        on: {
          click: function ($event) {
            return _vm.selectMenu("/referfriends")
          },
        },
      },
      [
        _c("div", {
          class: [
            "refer-friends-icon",
            _vm.$route.path.indexOf("/referfriends") == 0 ||
            _vm.$route.path.indexOf("/aceTip") == 0
              ? "refer-friends-icon-active"
              : "refer-friends-icon-normal",
          ],
        }),
        _vm._v(_vm._s(_vm.$t("Refer Friends"))),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }