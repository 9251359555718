<template>
  <div class="page-order-detail" v-if="orderDetail">
    <el-dialog :visible.sync="xgDialogVisible" center :show-close="false" width="550px" v-if="adviserOrXgInfo">
      <div class="main-title">{{adviserOrXgTitile}}</div>
      <div class="main-title">{{adviserOrXgTitilexiao}}</div>
      <div class="dialog-text">
        <div class="img" v-if="adviserOrXgInfo.wechatQrcode">
          <img :src="adviserOrXgInfo.wechatQrcode">
        </div>
        <div v-if="adviserOrXgInfo.wechat">{{$t('Wechat')}}：{{adviserOrXgInfo.wechat}}</div>
        <div v-if="adviserOrXgInfo.phone">{{$t('Mobile number')}}：{{adviserOrXgInfo.phone}}</div>
        <div v-if="adviserOrXgInfo.whatsapp">WhatApp：{{adviserOrXgInfo.whatsapp}}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="xgDialogVisible = false" >{{$t('Comfirm')}}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('Call the number below to get more services')" :visible.sync="tipDialogVisible" center :show-close="false" width="600px">
      <div class="dialog-text">
        <div>{{$t('Beijing office')}}：+86 010 62538271</div>
        <div>{{$t('Singapore office')}}：+65 68151111</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tipDialogVisible = false" class="footer-btn">{{$t('Comfirm')}}</el-button>
      </span>
    </el-dialog>
    <div class="title" :class="$i18n.locale == 'en' ? '' : 'zh-font'">{{$t('Order details')}}</div>
    <div class="page-order-detail-box">
      <div class="line-1" :class="$i18n.locale == 'en' ? '' : 'zh-font'">{{$t('Status')}}:
        <span v-if="orderDetail.status == 0 || orderDetail.status == 2">{{$t('Pending')}}</span>
        <span v-if="orderDetail.status == 1 || orderDetail.status == 4">{{$t('Paid')}}</span>
        <span v-if="orderDetail.status == 3">{{$t('Refunded')}}</span>
        <span v-if="orderDetail.status == 5 || orderDetail.status == 6">{{$t('Cancelled')}}</span>
      </div>
      <div class="line-2" v-if="orderDetail.status == 0 || orderDetail.status == 2">
        <!-- <order-count-item :overTime="orderDetail.overTime" page="detail"></order-count-item> -->
        <OrderCountItem :orderId="orderDetail.id" :overTime="orderDetail.overTime" page="detail" />
      </div>
      <div class="line-student">
        {{$t('Student')}}: {{orderDetail.studentUserName}}
      </div>
      <div class="line-3" v-if="orderDetail.status != 3">
        <div>{{$t('Top-uped product')}}：{{$i18n.locale == 'en' ? orderDetail.productNameEn : orderDetail.productNameCn}} </div>
        <div>{{$t('Top-uped points')}}：{{orderDetail.rechargeAmount}} {{$t('points')}}</div>
        <div v-if="orderDetail.incentiveAmount + orderDetail.referrerIncentiveAmount">{{$t('Awarded points')}}：{{orderDetail.incentiveAmount + orderDetail.referrerIncentiveAmount}}{{$t('Points')}}</div>
        <!-- <div>{{$t('Points type')}}：<span v-if="orderDetail.sgClassId">{{$t('sg Points')}}</span><span v-else>{{$t('Normal Points')}}</span></div> -->
        <div v-if="(orderDetail.status == 0 || orderDetail.status == 1 || orderDetail.status == 2 || orderDetail.status == 4) && orderDetail.originTotalPrice">{{$t('Original price')}}：{{orderDetail.originTotalPrice | dollarInteger}}.{{orderDetail.originTotalPrice | dollarDecimal}} {{orderDetail.currency}}</div>
        <div v-if="(orderDetail.status == 0 || orderDetail.status == 1 || orderDetail.status == 2 || orderDetail.status == 4) && orderDetail.originTotalPrice">{{$t('Discounted price')}}：{{orderDetail.savePrice | dollarInteger}}.{{orderDetail.savePrice | dollarDecimal}} {{orderDetail.currency}} </div>
        <div v-if="(orderDetail.status == 0 || orderDetail.status == 1 || orderDetail.status == 2 || orderDetail.status == 4) && orderDetail.taxFee">{{$t('GST')}}：{{orderDetail.taxFee | dollarInteger}}.{{orderDetail.taxFee | dollarDecimal}} {{orderDetail.currency}}</div>
        <div v-if="orderDetail.status == 1 || orderDetail.status == 4">{{$t('Paid price')}}：{{orderDetail.totalPrice | dollarInteger}}.{{orderDetail.totalPrice | dollarDecimal}} {{orderDetail.currency}} </div>
        <div v-if="(orderDetail.status == 0 || orderDetail.status == 1 || orderDetail.status == 2 || orderDetail.status == 4) && orderDetail.code">{{$t('Coupon code')}}：{{orderDetail.code}}</div>
        <div>{{$t('Order number')}}：{{orderDetail.orderNo}}</div>
        <div>{{$t('Order time')}}：{{DateTimeUtils.dateClockTime(orderDetail.createTime)}}</div>
        <div v-if="orderDetail.status == 5 || orderDetail.status == 6">{{$t('Cancellation time')}}：{{DateTimeUtils.dateClockTime(orderDetail.updateTime)}}</div>
        <div v-if="orderDetail.status == 1 || orderDetail.status == 4">{{$t('Payment time')}}：{{DateTimeUtils.dateClockTime(orderDetail.updateTime)}}</div>
        <div v-if="orderDetail.status == 1 || orderDetail.status == 4">
          {{$t('Payment Mode')}}：
          <span v-if="orderDetail.payType == 'card'">{{$t('Card payment')}}</span>
          <span v-if="orderDetail.payType == 'alipay'">{{$t('Alipay')}}</span>
          <span v-if="orderDetail.payType == 'wechat'">{{$t('Wechat')}}</span>
          <span v-if="orderDetail.payType == 'atome installment'">{{ orderDetail.payType }}</span>
          <span v-if="orderDetail.payType == 'Affirm'">{{ orderDetail.payType }}</span>
          <span v-if="orderDetail.payType == 'klarna'">{{ orderDetail.payType }}</span>
        </div>
        <div v-if="orderDetail.status == 1 || orderDetail.status == 4">
          <div class="receipt" v-if="orderDetail.receiptStatus != 9 || (orderDetail.receiptStatus == 9 && orderDetail.receiptUrl)">
            <span @click="openApplicationReceipt" v-if="openApplicationReceipt">{{$t('View reciept')}}</span>
            <span class="tips" v-else>{{$t('Only show receipts after 2020-01-01')}}</span>
            <!--             <img src="@/images/order/arrow_down_91.png" v-if="!receiptInputShow" @click="receiptInputShow = true">
            <img src="@/images/order/arrow_up_91.png" v-else @click="receiptInputShow = false">
            <div>
              <div class="receipt-input-box" v-if="receiptInputShow">
                <input type="text" placeholder="请输入接收发票的邮箱" v-model="email">
                <div class="btn" @click="openApplicationReceipt">申请</div>
              </div>
            </div> -->
          </div>
          <!-- <div class="receipt-result" v-if="orderDetail.sendSatus == 1 || orderDetail.sendSatus == 2"> -->
          <!--           <div class="receipt-result" v-else>
            <div class="receipt-result-title">
              <div>订单收据</div>
              <div class="resend" @click="reApplicationReceipt">重新申请</div>
            </div>
            <div class="receipt-result-detail">发送状态：{{orderDetail.sendSatus == 1 ? '发送成功' : '发送失败'}}</div>
            <div class="receipt-result-detail">接收邮箱：{{orderDetail.sendEmail}}</div>
            <div class="receipt-result-detail">发送时间：{{DateTimeUtils.dateClockTime(orderDetail.sendTime)}}</div>
          </div> -->
        </div>
        <div class="action-0" v-if="orderDetail.status == 0">
          <div class="cancel" @click="cancelOrder">{{$t('Cancel Order')}}</div>
          <div class="pay-box">
            <div class="amount">{{$t('Paid price')}}:<span style="color: #FFA13C;font-size: 20px;">  {{orderDetail.totalPrice | dollarInteger}}.{{orderDetail.totalPrice | dollarDecimal}} {{orderDetail.currency}}</span></div>
            <div class="pay-btn" @click="jumpToPayPage(orderDetail)">{{$t('Pay now')}}</div>
          </div>
        </div>
        <div class="action-1" v-if="orderDetail.status == 1 || orderDetail.status == 4">
          <div class="aftermarket" @click="getAdviserOrXgInfo">{{$t('Inquiry')}}</div>
        </div>
      </div>
      <div v-else>
        <div class="line-3">
          <div>{{$t('Top-uped product')}}：{{$i18n.locale == 'en' ? orderDetail.productNameEn : orderDetail.productNameCn}}</div>
          <div>{{$t('Amount Refunded')}}：{{orderDetail.refundMoney || '无'}}</div>
          <div>{{$t('Refund time')}}：{{DateTimeUtils.dateClockTime(orderDetail.refundTime)}}</div>
          <div>{{$t('Point used')}}：{{orderDetail.consumeLessonNum || '无'}}</div>
          <div>{{$t('Point retrieved')}}：{{orderDetail.collectionLessonNum || '无'}}</div>
          <div class="line-3-inner-box">
            <div class="line-3">
              <div style="color: #333;">{{$t('Order details')}}</div>
              <div>{{$t('Top-uped points')}}：{{orderDetail.rechargeAmount}}{{$t('Points')}}</div>
              <div v-if="orderDetail.incentiveAmount + orderDetail.referrerIncentiveAmount">{{$t('Awarded points')}}：{{orderDetail.incentiveAmount + orderDetail.referrerIncentiveAmount}}{{$t('Points')}}</div>
              <div>{{$t('Points type')}}：<span v-if="orderDetail.sgClassId">{{$t('sgPoints')}}</span><span v-else>{{$t('Normal Points')}}</span></div>
              <div v-if="orderDetail.originTotalPrice">{{$t('Original price')}}：{{orderDetail.currency}} {{orderDetail.originTotalPrice | dollarInteger}}.{{orderDetail.originTotalPrice | dollarDecimal}}</div>
              <div v-if="orderDetail.originTotalPrice">{{$t('Discounted price')}}：{{orderDetail.currency}} {{orderDetail.savePrice | dollarInteger}}.{{orderDetail.savePrice | dollarDecimal}}</div>
              <div v-if="orderDetail.taxFee">{{$t('GST')}}：{{orderDetail.currency}} {{orderDetail.taxFee | dollarInteger}}.{{orderDetail.taxFee | dollarDecimal}}</div>
              <div>{{$t('Paid price')}}：{{orderDetail.currency}} {{orderDetail.totalPrice | dollarInteger}}.{{orderDetail.totalPrice | dollarDecimal}}</div>
              <div>{{$t('Order number')}}：{{orderDetail.orderNo}}</div>
              <div>{{$t('Order time')}}：{{DateTimeUtils.dateClockTime(orderDetail.createTime)}}</div>
              <div>{{$t('Payment time')}}：{{DateTimeUtils.dateClockTime(orderDetail.updateTime)}}</div>
              <div>{{$t('Payment Mode')}}：<span v-if="orderDetail.payType == 'card'">{{$t('Card payment')}}</span><span v-if="orderDetail.payType == 'alipay'">{{$t('Alipay')}}</span><span v-if="orderDetail.payType == 'wechat'">{{$t('Wechat')}}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/index"
import { mapGetters } from 'vuex'
import OrderCountItem from '@/auto-components/Order/OrderCountItem'

export default {

  name: 'orderDetail',

  data() {
    return {
      orderDetail: null,
      adviserOrXgInfo: null,
      adviserOrXgTitile: null,
      adviserOrXgTitilexiao:null,
      xgDialogVisible: false,
      tipDialogVisible: false,
      receiptInputShow: false,
      email: null,
      receiptUrl: null
    }
  },

  components: {
    OrderCountItem
  },

  created() {
    this.getOrder()
  },

  computed: {
    ...mapGetters({
      parentInfo: 'user/getParentInfo'
    }),
  },

  mounted() {
  },

  methods: {
    // 获取订单
    getOrder() {
      let orderNo = this.$route.query.orderNo
      api.order.getOrderDetail(orderNo).then(res => {
        if (res.status === 200 && res.data.code === 200) {
          this.orderDetail = res.data.data
          if (this.orderDetail.status == 1 || this.orderDetail.status == 4) {
            this.getApplicationReceipt()
          }
        } else {
          this.$message(res.data.message)
        }
      })
    },
    // 取消订单
    cancelOrder() {
      let orderId = this.$route.query.orderId
      this.$confirm(this.$i18n.t('Confirm to cancel this order?'), this.$i18n.t('Prompt'), {
        confirmButtonText: this.$i18n.t('Later'),
        cancelButtonText: this.$i18n.t('Cancel Order'),
        closeOnClickModal:false,
        distinguishCancelAndClose: true,
      }).then(() => {
        console.log('用户点击了我再想想')
      }).catch((action) => {
        console.log('用户点击了取消订单')
        if (action==='cancel') {
           api.order.cancelOrder(orderId).then(res => {
            this.refreshPendingOrderCount()
            if (res.status === 200 && res.data.code === 200) {
              this.$message.success(this.$i18n.t('Cancel Order Success'))
              this.getOrder()
            } else {
              this.$message(res.data.message)
            }
          })
        }
      });
    },
    // 跳转至支付页面
    jumpToPayPage(item) {
      const userId = this.parentInfo.id
      this.$api.order.getUserParentUndoNumber(userId).then(res => {
        if (res && res.data && res.data.code === 200) {
          // 未支付订单信息
          let index;
          if (res.data.data.length==2 && res.data.data.filter((ele,i)=>{
              if (item.orderNo==ele.orderNo) {
                index=i
              }
              return ele.productPackageId == item.productPackageId
            }).length>1 && Math.abs(new Date(res.data.data[0].createTime).getTime() - new Date(res.data.data[1].createTime).getTime()) < 5*60*1000 && index==0) {
            this.$confirm(this.$t('You have an unpaid order for the same product. There might be a network delay to update the payment status.'), this.$t('Tips'), {
              confirmButtonText: this.$t('confirm'),
              cancelButtonText: this.$t('cancel'),
              distinguishCancelAndClose: true,
              type: 'warning'
            }).then(() => {
              if (item.sgClassId || item.groupId || item.packageid) {
                const origin = window.origin
                window.location.replace(`${origin}/billList.html?orderNo=${item.orderNo}`)
              } else {
                this.$router.push(`/pay/billing?orderNo=${item.orderNo}`)
              }
            }).catch((action) => {
              if (action === 'cancel') {
                // this.$router.push(`/order/orderCenter`)
              }
            })
          }else{
            if (item.sgClassId || item.groupId || item.packageid) {
              const origin = window.origin
              window.location.replace(`${origin}/billList.html?orderNo=${item.orderNo}`)
            } else {
              this.$router.push(`/pay/billing?orderNo=${item.orderNo}`)
            }
          }
        } else {
          this.$message({
            message: res.data.message,
          })
        }
      })
    },
    // 申请售后
    getAdviserOrXgInfo() {
      const { id, studentUserId } = this.orderDetail;
      api.order.getAdviserOrXgInfo({ studentUserId, orderId: id }).then(res => {
        if (res.status === 200 && res.data.code === 200) {
          let info = res.data.data
          this.adviserOrXgInfo = info.xgInfo ? info.xgInfo : info.adviserInfo
          let name = null
          if (this.adviserOrXgInfo) {
            name = this.adviserOrXgInfo.nameEn ? this.adviserOrXgInfo.nameEn : this.adviserOrXgInfo.realName
          }
          if (this.adviserOrXgInfo) {
            this.adviserOrXgTitile = this.$i18n.locale == 'en' ? `For any inquiries, please contact your`: `售后问题请联系您的专属课程顾问 ${name} 老师提供帮助`
            this.adviserOrXgTitilexiao=this.$i18n.locale == 'en' ? `course consultant ${name}`: ``
            this.xgDialogVisible = true
          } else {
            this.tipDialogVisible = true
          }
        } else {
          this.$message(res.data.message)
        }
      })
    },
    getApplicationReceipt() {
      let orderId = this.$route.query.orderId
      api.order.applyToSendOrderInfo(orderId).then(res => {
        if (res.status === 200 && res.data.code === 200) {
          this.receiptUrl = res.data.data
        } else {
          this.$message(res.data.message)
        }
      })
    },
    // 申请收据
    openApplicationReceipt() {
      if (this.orderDetail.receiptStatus === 1) {
        // const message =
        // this.$message(message)
        this.$message({
          message: this.$i18n.locale === 'en' ? 'The receipt is ready. Please check it out !' : '收据生成中，请稍后查看' ,
          center: true
        });
      } else {
        window.open(this.orderDetail.receiptUrl)
      }
    },
    // 重新发送
    reApplicationReceipt() {
      this.orderDetail.sendSatus = 0
      this.receiptInputShow = true
    },
    // 刷新统计未支付订单
    refreshPendingOrderCount() {
      this.$EventBus.$emit("onRefreshPendingOrderCount");
    },
  }

}

</script>
<style scoped lang="stylus">
* {
  box-sizing: border-box;
}

.page-order-detail {
  width: 800px;
  margin: 10px auto;

  .main-title {
    font-size: 24px;
    line-height: 36px;
    color: #34364d;
    text-align: center;
    margin-bottom: 20px;
  }

  .dialog-text {
    text-align: center;

    >div {
      margin-bottom: 20px;

      img {
        width: 150px;
        height: 150px;
        /*background: red;*/
      }
    }
  }

  .zh-font {
    font-family: SofiaProSoftMedium,Themegeneral!important;
  }

  .title {
    color: #34364D;
    font-size: 28px;
    margin-bottom: 8px;
    font-family: SofiaProSoftBold,Themegeneral;
  }

  .page-order-detail-box {
    width: 100%;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 4px rgba(182, 182, 182, 0.08);
    border-radius: 14px;
    padding: 25px 28px 85px;

    .line-1 {
      color: #34364D;
      font-size: 20px;
      margin-bottom: 10px;
      font-family: SofiaProSoftMedium,Themegeneral;

    }

    .line-student {
      color: #666;
      font-size: 14px;
      line-height: 22px;
    }

    .line-2 {
      color: #666;
      font-size: 14px;
      margin-bottom: 10px;
    }

    .line-3 {
      color: #666;
      font-size: 14px;
      line-height: 22px;

      .line-3-inner-box {
        background: #f8f8f8;
        padding: 15px 20px;
        margin-top: 10px;
      }

      .receipt {
        color: #7580E5;
        font-size: 16px;
        margin-top: 10px;
        cursor: pointer;

        .tips {
          font-size: 12px;
          color: #999;
          float: right;
        }

        img {
          width: 20px;
          vertical-align: middle;
          cursor: pointer;
        }

        .receipt-input-box {
          position: relative;
          margin-top: 10px;
          width: 440px;

          input {
            width: 440px;
            height: 40px;
            border-radius: 6px;
            border: 1px solid rgba(178, 178, 178, 1);
            padding: 0 90px 0 10px;
            font-size: 16px;
          }

          input::-webkit-input-placeholder {
            font-size: 16px;
          }

          .btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 88px;
            height: 40px;
            background: #EFA83E;
            color: #fff;
            text-align: center;
            line-height: 40px;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            cursor: pointer;
            font-size: 14px;
          }
        }
      }

      .receipt-result {
        margin-top: 10px;

        .receipt-result-title {
          font-size: 16px;
          display: flex;
          color: #34364D;
          margin-bottom: 10px;
          justify-content: space-between;

          .resend {
            color: #6E70A0;
            cursor: pointer;
          }
        }
      }
    }

    .action-0 {
      .cancel {
        position: absolute;
        right: 30px;
        top: 24px;
        color: #7580E5;
        font-size: 16px;
        cursor: pointer;
      }

      .pay-box {
        display: flex;
        position: absolute;
        right: 30px;
        bottom: 30px;
        font-size: 14px;
        line-height: 40px;

        .pay-btn {
          width: 100px;
          height: 40px;
          background: #FFA13C;
          border-radius: 44px;
          color: #fff;
          text-align: center;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }

    .action-1 {
      .aftermarket {
        position: absolute;
        top: 30px;
        right: 20px;
        width: 100px;
        height: 40px;
        border-radius: 44px;
        border: 2px solid #7580E5;
        color: #7580E5;
        background: #fff;
        text-align: center;
        line-height: 33px;
        cursor: pointer;
      }
    }
  }
}
.footer-btn{
  width: 236px;
  height: 50px;
}

</style>
