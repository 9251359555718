var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      staticClass: "m-students-lists",
      style: {
        width: _vm.isShowAll
          ? 200 + _vm.$store.state.student.students.length * 130 < 950
            ? 200 + _vm.$store.state.student.students.length * 130 + "px"
            : "950px"
          : "200px",
      },
      attrs: { shadow: "always" },
      nativeOn: {
        mouseenter: function ($event) {
          return _vm.ifShowAllStudent.apply(null, arguments)
        },
        mouseleave: function ($event) {
          _vm.isShowAll = false
        },
      },
    },
    [
      _c(
        "div",
        { ref: "list", staticClass: "students" },
        [
          _vm._l(_vm.allStudents, function (item, key) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "item",
                on: {
                  click: function ($event) {
                    return _vm.changeCurrentStudent(key, item)
                  },
                },
              },
              [
                _c("el-avatar", {
                  staticClass: "c-student",
                  attrs: { size: 80, src: item.avatar, fit: "fill" },
                }),
                _vm._v(" "),
                item.id == _vm.currentst
                  ? _c("span", { staticClass: "avatar-bg" })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(item.realName)),
                ]),
              ],
              1
            )
          }),
          _vm._v(" "),
          (_vm.isShowAll && _vm.allStudents.length < 20) ||
          _vm.allStudents.length === 0
            ? _c(
                "div",
                {
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.openStudent()
                    },
                  },
                },
                [
                  _c("el-avatar", {
                    staticClass: "c-avatar-plus",
                    attrs: { size: 80, src: _vm.avatarUrl },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [_vm._v("Add")]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "arrow" }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isShowMoreStudentBtn
        ? _c("i", {
            staticClass: "icon-left",
            class: { right: _vm.isShowAll },
            on: {
              click: function ($event) {
                return _vm.showOrCloseStuList()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("AddStudent", {
        attrs: { open: _vm.isShow, type: 2 },
        on: { close: _vm.closeStudent },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }