import apiConsts from '../../utils/api_constants';
import api from '../../api'

const state = {

};

const getters = {

};

const actions = {

};

const mutations = {};


export default { namespaced: true, state, mutations, actions, getters }
