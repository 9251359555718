import vue from 'vue';
import vuex from 'vuex';
import createLogger from "vuex/dist/logger";
import createPersistedState from "vuex-persistedstate"; 
vue.use(vuex)

// 自动导入module下所有模块
const modules = (modulesFiles => {
  return modulesFiles.keys().reduce((modules, modulePath) => {
      const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
      const value = modulesFiles(modulePath);
      modules[moduleName] = value.default;
      return modules;
  }, {});
})(require.context('./modules', false, /\.js$/));
const debug = process.env.NODE_ENV !== "production";
// 添加vuex持久化 支付跳转那里有问题先关闭
const createPersisted = createPersistedState({
  key: 'lingoace',
  reducer(val) {
      return {
          ...val
      };
  }
});
const store = new vuex.Store({
  modules,
  plugins: debug ? [createLogger()] : [] 
  
});

export default store;
