var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "add-student",
      attrs: {
        title: _vm.isIndonesia ? "Tambahkan murid" : _vm.$t("Add Student"),
        visible: _vm.isOpen,
        width: "400px",
        "show-close": false,
        center: true,
        "destroy-on-close": true,
        "append-to-body": "",
        "close-on-click-modal": _vm.canClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isOpen = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabType == 1,
              expression: "tabType==1",
            },
          ],
          ref: "studentForm",
          attrs: {
            model: _vm.student,
            rules: _vm.rules,
            "label-width": "90px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "realName",
                label: _vm.isIndonesia ? "Nama" : _vm.$t("Name"),
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "250px" },
                attrs: {
                  placeholder: _vm.isIndonesia
                    ? "masukkan nama murid"
                    : _vm.$t("Pls enter student’s name"),
                },
                model: {
                  value: _vm.student.realName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.student,
                      "realName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "student.realName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              class: { "ido-width": _vm.isIndonesia },
              attrs: {
                prop: "gender",
                label: _vm.isIndonesia ? "Jenis kelamin" : _vm.$t("Gender"),
              },
            },
            [
              _c("Gender", {
                attrs: {
                  gender: _vm.student.gender,
                  isIndonesia: _vm.isIndonesia,
                },
                on: { change: _vm.changeGender },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              class: { "ido-width": _vm.isIndonesia },
              attrs: {
                prop: "birthday",
                label: _vm.isIndonesia ? "Tanggal lahir" : _vm.$t("Age"),
              },
            },
            [
              _c("el-date-picker", {
                staticStyle: { width: "250px" },
                attrs: {
                  "popper-class": "picker",
                  "default-value": _vm.valueDefaultShow,
                  type: "date",
                  "picker-options": _vm.pickerOptions,
                  placeholder: _vm.isIndonesia
                    ? "Pilih tanggal lahir murid"
                    : _vm.$t("Pls choose sudent’s birthday"),
                },
                model: {
                  value: _vm.student.birthday,
                  callback: function ($$v) {
                    _vm.$set(_vm.student, "birthday", $$v)
                  },
                  expression: "student.birthday",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  staticClass: "wp100 mb30 mt30",
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.addNewChild("studentForm")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.isIndonesia
                          ? "Tambahkan murid"
                          : _vm.$t("ADD STUDENT")
                      )
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabType == 2,
              expression: "tabType==2",
            },
          ],
          ref: "studentForm2",
          attrs: {
            model: _vm.linkAccount,
            rules: _vm.rulesLinkAccount,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "identify", label: _vm.$t("Account") } },
            [
              _c("el-input", {
                staticStyle: { width: "248px" },
                attrs: { placeholder: _vm.$t("Enter existing account") },
                model: {
                  value: _vm.linkAccount.identify,
                  callback: function ($$v) {
                    _vm.$set(_vm.linkAccount, "identify", $$v)
                  },
                  expression: "linkAccount.identify",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "password", label: _vm.$t("Password") } },
            [
              _c("el-input", {
                staticStyle: { width: "248px" },
                attrs: {
                  type: "password",
                  placeholder: _vm.$t("Pls enter student’s password"),
                },
                model: {
                  value: _vm.linkAccount.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.linkAccount, "password", $$v)
                  },
                  expression: "linkAccount.password",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  staticClass: "wp100 mb30 mt30",
                  attrs: { type: "primary", loading: _vm.loading2 },
                  on: {
                    click: function ($event) {
                      return _vm.addLinkAccount("studentForm2")
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("ADD STUDENT")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Upload", {
        on: { "crop-success": _vm.getFiles },
        model: {
          value: _vm.isShowUpload,
          callback: function ($$v) {
            _vm.isShowUpload = $$v
          },
          expression: "isShowUpload",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }