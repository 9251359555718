import CC from "cookieconsent"
/**
 * cookieconsent初始化
 * @3版本不支持模块化导出
 */
window.cookieconsent.initialise({
  "palette": {
    "popup": {
      "background": "#237afc"
    },
    "button": {
      "background": "#fff",
      "text": "#237afc"
    }
  },
  "type": "opt-out",
  "content": {
    "message": "This website uses cookies to ensure you get the best experience on our website.",
    "href": "https://www.lingoace.com/cookie-policy/",
    "dismiss": 'Got it!',
    "allow": 'Allow cookies',
    "deny": '',
  },
  "position": "bottom",
});
